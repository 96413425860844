.text-x-sm {
  font-size: 0.5rem !important; }

.text-sm {
  font-size: 0.8rem !important; }

.text-md {
  font-size: 1rem !important; }

.text-lg {
  font-size: 1.5rem !important; }

.text-x-lg {
  font-size: 2.5rem !important; }

.text-xx-lg {
  font-size: 3rem !important; }

.font-weight-100 {
  font-weight: 100 !important; }

.font-weight-200 {
  font-weight: 200 !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-400 {
  font-weight: 400 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.font-weight-800 {
  font-weight: 800 !important; }

.font-weight-900 {
  font-weight: 900 !important; }

.color-black {
  color: #000; }

.color-white {
  color: #fff; }

.color-grey-xx-light {
  color: #ebecec; }

.color-grey-x-light {
  color: #d6d7d9; }

.color-grey-light {
  color: #f4f4f4; }

.color-grey-soft {
  color: #eee8e8; }

.color-grey {
  color: #797e83; }

.color-grey-dark {
  color: #656a6e; }

.color-grey-x-dark {
  color: #525559; }

.color-yellow {
  color: #ecaf2d; }

.color-green {
  color: #5ad864; }

.color-red-light {
  color: #f2eae7; }

.color-red {
  color: #aa8c8a; }

.color-red-dark {
  color: #9c676c; }

.fill-black {
  fill: #000; }

.fill-white {
  fill: #fff; }

.fill-grey-xx-light {
  fill: #ebecec; }

.fill-grey-x-light {
  fill: #d6d7d9; }

.fill-grey-light {
  fill: #f4f4f4; }

.fill-grey-soft {
  fill: #eee8e8; }

.fill-grey {
  fill: #797e83; }

.fill-grey-dark {
  fill: #656a6e; }

.fill-grey-x-dark {
  fill: #525559; }

.fill-yellow {
  fill: #ecaf2d; }

.fill-green {
  fill: #5ad864; }

.fill-red-light {
  fill: #f2eae7; }

.fill-red {
  fill: #aa8c8a; }

.fill-red-dark {
  fill: #9c676c; }

.bg-black {
  background-color: #000; }

.bg-white {
  background-color: #fff; }

.bg-grey-xx-light {
  background-color: #ebecec; }

.bg-grey-x-light {
  background-color: #d6d7d9; }

.bg-grey-light {
  background-color: #f4f4f4; }

.bg-grey-soft {
  background-color: #eee8e8; }

.bg-grey {
  background-color: #797e83; }

.bg-grey-dark {
  background-color: #656a6e; }

.bg-grey-x-dark {
  background-color: #525559; }

.bg-yellow {
  background-color: #ecaf2d; }

.bg-green {
  background-color: #5ad864; }

.bg-red-light {
  background-color: #f2eae7; }

.bg-red {
  background-color: #aa8c8a; }

.bg-red-dark {
  background-color: #9c676c; }

.title-cart {
  font-weight: 700;
  font-size: 24px; }

@keyframes bounce {
  0% {
    transform: translate(-50%, 0); }
  5% {
    transform: translate(-50%, 8px); }
  10% {
    transform: translate(-50%, 0); }
  15% {
    transform: translate(-50%, 8px); }
  20% {
    transform: translate(-50%, 0); } }

.alert {
  border-radius: 0;
  margin: 4px 0 4px 0; }
  .alert p {
    color: inherit; }

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
  padding: .5em 1.5em .5em .5em; }

body {
  font-family: 'Raleway', sans-serif;
  background-color: #FFFFFF;
  color: #52565A; }
  body.alternative {
    font-family: 'Open Sans', sans-serif; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-size: 30px;
  font-weight: 300;
  color: #000000; }

h2.print {
  font-family: 'Raleway', sans-serif !important;
  font-size: 22px; }

p {
  font-size: 13px;
  color: #808080; }

@media (min-width: 1440px) {
  .col-xl-2 {
    width: calc(calc(100% / 12) * 2); }
  .col-xl-10 {
    width: calc(calc(100% / 12) * 10); }
  .container {
    width: 1410px; } }

@media (min-width: 1920px) {
  .container {
    width: 1890px; } }

.btn {
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: auto;
  font-weight: normal; }

.btn ~ .btn {
  margin-left: 20px; }

.btn-primary {
  text-decoration: none;
  background-color: #A4C3C8;
  color: #FFFFFF; }
  .btn-primary:focus, .btn-primary:hover, .btn-primary:active {
    text-decoration: none;
    background-color: #6EA9B3;
    color: #FFFFFF; }

.btn-default {
  text-decoration: none;
  background-color: #C1D42F;
  color: #FFFFFF; }
  .btn-default:focus, .btn-default:hover, .btn-default:active {
    text-decoration: none;
    background-color: #C1D42F;
    color: #FFFFFF; }

.btn-lg {
  border-radius: 0;
  min-width: 200px;
  height: 50px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: normal;
  text-align: center;
  box-shadow: none; }

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active {
  background-color: #6EA9B3;
  border-color: #6EA9B3; }

.btn.btn-action {
  background-color: #A4C3C8;
  color: #FFFFFF;
  box-shadow: none;
  line-height: 20px;
  border-radius: 0;
  font-weight: 400;
  min-width: 230px;
  padding: 15px;
  margin: 0;
  transition: all .2s ease-in-out; }
  .btn.btn-action:focus, .btn.btn-action:hover {
    text-decoration: none;
    background-color: #6EA9B3;
    color: #FFFFFF; }

.form-control {
  height: 41px;
  font-size: 17px;
  line-height: 25px;
  padding: 7px 11px 7px 11px;
  border-radius: 5px;
  font-weight: 300;
  border: 1px solid #898989;
  box-shadow: none;
  letter-spacing: 1px;
  background-color: #FFFFFF;
  color: #585758;
  outline: none; }

.form-control:focus {
  border-color: #A4C3C8;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(164, 195, 200, 0.6); }

.form-control-static {
  min-height: 41px;
  padding: 8px 0 8px 0;
  line-height: 25px;
  margin-bottom: 0; }

@media (max-width: 1200px) {
  .form-control {
    border-radius: 0;
    height: 45px;
    line-height: 23px;
    padding: 10px 11px 10px 11px;
    font-size: 12px; } }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 8px;
    margin-bottom: 0;
    line-height: 25px;
    text-align: right; } }

.header {
  position: relative;
  background: white;
  border-bottom: 1px solid #EEEEEE;
  width: 100%;
  z-index: 4; }
  .header .header-wrap {
    position: relative;
    background-color: transparent; }
  .header .container {
    position: relative; }
    .header .container .header-mobile-logo-text {
      display: none; }
    .header .container .header-mobile-placeholder {
      display: none; }
    .header .container .header-mobile-logo {
      display: none; }
    .header .container .header-mobile-burger {
      display: none; }
    .header .container .header-mobile-account {
      display: none; }
    .header .container .header-link {
      position: relative;
      display: block;
      height: 40px; }
      .header .container .header-link a {
        color: #585758;
        font-size: 13px;
        font-weight: 300;
        text-align: right;
        width: auto;
        border-radius: 0;
        border: 0;
        padding: 10px 15px 10px 15px;
        text-transform: uppercase;
        letter-spacing: normal;
        position: relative;
        display: inline-block;
        max-width: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px; }
        .header .container .header-link a:hover {
          text-decoration: none;
          color: #A6C6CC; }
        .header .container .header-link a.active {
          color: #A6C6CC; }
      .header .container .header-link.alternative {
        margin: 0 1%; }
        .header .container .header-link.alternative a {
          font-weight: 600 !important;
          color: #000000 !important;
          text-transform: capitalize !important;
          font-size: 16px; }
          @media (max-width: 1200px) {
            .header .container .header-link.alternative a {
              font-size: 12px; } }
    .header .container .header-top {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      height: 40px;
      margin-left: -20px;
      margin-right: -20px; }
      .header .container .header-top .header-side-left {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 40px; }
      .header .container .header-top .header-side-middle {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 40px; }
      .header .container .header-top .header-side-right {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: end;
        justify-content: flex-end;
        min-height: 40px; }
      .header .container .header-top .header-info {
        padding: 0 20px 0 20px;
        color: #9E9E9E;
        font-size: 13px;
        line-height: 15px; }
        .header .container .header-top .header-info span,
        .header .container .header-top .header-info i,
        .header .container .header-top .header-info a,
        .header .container .header-top .header-info a:hover {
          color: #9E9E9E;
          font-size: 13px;
          line-height: 15px;
          text-decoration: none; }
    .header .container .header-side-left {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: start;
      justify-content: flex-start;
      min-height: 40px;
      max-height: none; }
    .header .container .header-side-right {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-preferred-size: 400px;
      flex-basis: 400px;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: end;
      justify-content: flex-end;
      min-height: 70px;
      max-height: none; }
    .header .container .header-bottom {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      height: 90px;
      margin-left: -20px;
      margin-right: -20px; }
      .header .container .header-bottom .flex-left {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 0 20px 0 20px; }
      .header .container .header-bottom .flex-right {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 500px;
        flex-basis: 500px;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 20px 0 20px;
        background-color: transparent;
        margin-top: 10px;
        margin-bottom: 10px; }
        .header .container .header-bottom .flex-right.alternative {
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%; }
          .header .container .header-bottom .flex-right.alternative .dropdown-user {
            width: max-content; }
            .header .container .header-bottom .flex-right.alternative .dropdown-user i {
              display: none; }
            .header .container .header-bottom .flex-right.alternative .dropdown-user .alternative-icon {
              display: block;
              height: 40px;
              width: 50px;
              background-image: url("/themes/standard/images/milestone-user.png");
              background-size: 65px;
              background-position: center center;
              background-repeat: no-repeat; }
  .header .dropdown-categories-brands,
  .header .dropdown-pages,
  .header .dropdown-user,
  .header .dropdown-currency,
  .header .dropdown-languages {
    display: block;
    width: auto; }
    .header .dropdown-categories-brands .dropdown-toggle,
    .header .dropdown-pages .dropdown-toggle,
    .header .dropdown-user .dropdown-toggle,
    .header .dropdown-currency .dropdown-toggle,
    .header .dropdown-languages .dropdown-toggle {
      display: block;
      color: #585758;
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      text-align: right;
      width: auto;
      border-radius: 0;
      border: 0;
      padding: 10px 15px 10px 15px;
      text-transform: uppercase;
      letter-spacing: normal; }
      .header .dropdown-categories-brands .dropdown-toggle:focus,
      .header .dropdown-pages .dropdown-toggle:focus,
      .header .dropdown-user .dropdown-toggle:focus,
      .header .dropdown-currency .dropdown-toggle:focus,
      .header .dropdown-languages .dropdown-toggle:focus {
        text-decoration: none; }
    .header .dropdown-categories-brands .dropdown-menu,
    .header .dropdown-pages .dropdown-menu,
    .header .dropdown-user .dropdown-menu,
    .header .dropdown-currency .dropdown-menu,
    .header .dropdown-languages .dropdown-menu {
      min-width: 100%;
      width: auto;
      max-height: none;
      overflow-y: auto;
      margin: 0;
      border-radius: 0;
      border: 1px solid #898989;
      padding: 10px;
      text-align: center; }
      .header .dropdown-categories-brands .dropdown-menu li a,
      .header .dropdown-pages .dropdown-menu li a,
      .header .dropdown-user .dropdown-menu li a,
      .header .dropdown-currency .dropdown-menu li a,
      .header .dropdown-languages .dropdown-menu li a {
        font-size: 17px;
        line-height: 17px;
        padding: 12px;
        color: #898989; }
      .header .dropdown-categories-brands .dropdown-menu li .btn,
      .header .dropdown-pages .dropdown-menu li .btn,
      .header .dropdown-user .dropdown-menu li .btn,
      .header .dropdown-currency .dropdown-menu li .btn,
      .header .dropdown-languages .dropdown-menu li .btn {
        font-size: 17px;
        line-height: 17px;
        padding: 12px;
        margin-top: 19px;
        border-radius: 5px;
        background-color: #396369;
        color: #ffffff;
        width: 100%;
        height: auto;
        border: 0;
        font-weight: 700; }
      .header .dropdown-categories-brands .dropdown-menu .divider,
      .header .dropdown-pages .dropdown-menu .divider,
      .header .dropdown-user .dropdown-menu .divider,
      .header .dropdown-currency .dropdown-menu .divider,
      .header .dropdown-languages .dropdown-menu .divider {
        background-color: #898989; }
  .header .dropdown-pages .dropdown-toggle {
    text-align: center; }
  .header .dropdown-pages .dropdown-menu {
    text-align: left; }
  .header .dropdown-currency,
  .header .dropdown-languages {
    width: auto; }
    .header .dropdown-currency .dropdown-toggle,
    .header .dropdown-languages .dropdown-toggle {
      width: auto; }
  .header .dropdown-categories-brands {
    display: block;
    width: auto;
    height: 40px; }
    .header .dropdown-categories-brands.alternative {
      margin: 0 1%; }
      .header .dropdown-categories-brands.alternative .dropdown-toggle {
        text-transform: capitalize !important;
        color: #000000 !important;
        font-size: 16px;
        font-weight: 600 !important; }
        @media (max-width: 1200px) {
          .header .dropdown-categories-brands.alternative .dropdown-toggle {
            font-size: 12px; } }
    .header .dropdown-categories-brands .dropdown-toggle {
      width: auto;
      line-height: 20px;
      text-align: left; }
      .header .dropdown-categories-brands .dropdown-toggle:hover {
        text-decoration: none;
        color: #A6C6CC; }
    .header .dropdown-categories-brands .dropdown-menu {
      padding: 20px;
      height: auto;
      max-width: 1200px; }
      .header .dropdown-categories-brands .dropdown-menu .list-wrap {
        position: relative;
        padding: 0;
        background-image: url("/themes/standard/images/border.png");
        background-size: 1px;
        background-repeat: repeat-y;
        background-position: 280px 0; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
          position: relative;
          width: 270px;
          max-width: 280px;
          padding-right: 20px; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-title {
            color: #7D7D7D;
            font-size: 17px;
            line-height: 21px;
            font-weight: 300;
            text-align: left;
            text-transform: uppercase;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-title a {
              display: inline-block;
              color: #7D7D7D;
              height: 21px; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories {
            color: #7D7D7D;
            font-size: 16px;
            line-height: 1.2;
            font-weight: 300;
            text-align: left;
            min-width: 0; }
            .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories a {
              display: block;
              padding: 7px 0 7px 0;
              text-decoration: none;
              color: #7D7D7D;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
              .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories a:hover {
                color: #A6C6CC;
                text-decoration: none;
                cursor: pointer; }
              .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories a:last-child {
                padding-bottom: 0; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right {
          position: relative;
          padding-left: 10px;
          padding-right: 10px; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title {
            color: #7D7D7D;
            font-size: 17px;
            line-height: 21px;
            font-weight: 300;
            text-align: left;
            text-transform: uppercase;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding-left: 10px; }
            .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title a {
              display: inline-block;
              color: #7D7D7D;
              height: 21px; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands {
            color: #7D7D7D;
            font-size: 16px;
            line-height: 1.2;
            font-weight: 300;
            text-align: left;
            padding-left: 0;
            min-width: 0; }
            .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands a {
              display: block;
              padding: 7px 0 7px 10px;
              text-decoration: none;
              color: #7D7D7D;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
              .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands a:hover {
                color: #A6C6CC;
                text-decoration: none;
                cursor: pointer; }
              .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands a:last-child {
                padding-bottom: 0; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left,
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right {
          height: auto;
          min-height: 100px;
          max-height: 470px; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-title,
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title {
            height: 25px; }
      .header .dropdown-categories-brands .dropdown-menu .list-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-title {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 0 1 auto;
          flex: 0 1 auto; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 0 0 280px;
          flex: 0 0 280px;
          -ms-flex-direction: column;
          flex-direction: column; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-direction: column;
          flex-direction: column; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-categories-wrap {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          -ms-flex-direction: row;
          flex-direction: row; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-categories-wrap .list-categories {
            display: -ms-flexbox;
            display: flex;
            -ms-flex: 1 1 100%;
            flex: 1 1 100%;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-item-align: start;
            align-self: flex-start; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-brands-wrap {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          -ms-flex-direction: row;
          flex-direction: row; }
          .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-brands-wrap .list-brands {
            display: -ms-flexbox;
            display: flex;
            -ms-flex: 1 1 25%;
            flex: 1 1 25%;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-item-align: start;
            align-self: flex-start; }
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-brands-wrap,
        .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-categories-wrap {
          max-height: 460px;
          overflow: auto; }
  .header .dropdown-pages .dropdown-toggle:hover {
    text-decoration: none;
    color: #A6C6CC; }
  .header .dropdown-pages .dropdown-toggle.active {
    color: #A6C6CC; }
  .header .dropdown-pages .dropdown-menu li a {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase; }
  .header .dropdown-pages,
  .header .dropdown-languages,
  .header .dropdown-currency,
  .header .dropdown-user {
    display: block; }
    .header .dropdown-pages.alternative .dropdown-toggle,
    .header .dropdown-languages.alternative .dropdown-toggle,
    .header .dropdown-currency.alternative .dropdown-toggle,
    .header .dropdown-user.alternative .dropdown-toggle {
      color: #000000 !important; }
      @media (max-width: 767px) {
        .header .dropdown-pages.alternative .dropdown-toggle,
        .header .dropdown-languages.alternative .dropdown-toggle,
        .header .dropdown-currency.alternative .dropdown-toggle,
        .header .dropdown-user.alternative .dropdown-toggle {
          display: none; } }
    .header .dropdown-pages .dropdown-toggle,
    .header .dropdown-languages .dropdown-toggle,
    .header .dropdown-currency .dropdown-toggle,
    .header .dropdown-user .dropdown-toggle {
      position: relative;
      height: 40px; }
  .header.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    border-bottom: 1px solid transparent; }
    .header.header-transparent .header-wrap {
      position: relative; }
    .header.header-transparent .dropdown-pages .dropdown-toggle,
    .header.header-transparent .dropdown-languages .dropdown-toggle,
    .header.header-transparent .dropdown-currency .dropdown-toggle,
    .header.header-transparent .dropdown-user .dropdown-toggle {
      color: #FFFFFF; }
    .header.header-transparent .dropdown-categories-brands .dropdown-toggle {
      color: #FFFFFF; }
    .header.header-transparent .container .header-link a {
      color: #FFFFFF; }
    .header.header-transparent .container .header-top .header-info {
      color: #FFFFFF; }
      .header.header-transparent .container .header-top .header-info span,
      .header.header-transparent .container .header-top .header-info i,
      .header.header-transparent .container .header-top .header-info a,
      .header.header-transparent .container .header-top .header-info a:hover {
        color: #FFFFFF; }

.header-bottom-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 5px;
  margin-left: -15px;
  margin-right: -15px; }
  .header-bottom-menu .flex-menu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1; }

.dropdown-user .dropdown-menu {
  left: auto;
  right: 0; }

.dropdown-menu .btn {
  font-size: 17px;
  line-height: 17px;
  padding: 12px;
  margin-top: 19px;
  border-radius: 0;
  background-color: #A4C3C8;
  color: #FFFFFF;
  width: 100%;
  height: auto;
  border: 0;
  font-weight: normal;
  transition: all .2s ease-in-out; }
  .dropdown-menu .btn:hover {
    background-color: #6EA9B3;
    color: #FFFFFF; }

div.logo {
  position: relative;
  width: 240px;
  height: 60px; }
  div.logo a {
    display: block;
    max-width: 240px;
    height: 60px;
    background: no-repeat center left;
    background-size: contain;
    text-decoration: none; }
    div.logo a:hover {
      text-decoration: none; }

div.search {
  display: block;
  position: relative;
  width: 270px;
  height: 40px;
  margin-top: 0;
  margin-right: 20px; }
  div.search input {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #7B7979;
    color: #585758;
    border-radius: 14px;
    width: 270px;
    height: 40px;
    padding: 11px 40px 11px 15px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    background-image: url("/themes/standard/images/icon-search.png");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: right 10px center;
    outline: none; }
    div.search input.alternative {
      background-image: url("/themes/standard/images/milestone-search.png");
      background-size: 50px !important; }
    div.search input:-ms-input-placeholder {
      color: #9E9E9E; }
    div.search input::placeholder {
      color: #9E9E9E; }

.dropdown-cart {
  position: relative;
  width: 48px;
  height: 48px; }
  .dropdown-cart .dropdown-toggle {
    width: 48px; }
  .dropdown-cart .cart-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border: 3px solid #7B7979;
    border-radius: 100%;
    background-image: url("/themes/standard/images/icon-cart.png");
    background-repeat: no-repeat;
    background-size: 30px 26px;
    background-position: center center; }
    .dropdown-cart .cart-icon.alternative {
      background-image: url("/themes/standard/images/milestone-basket.png");
      background-size: 70px !important;
      border: none; }
    .dropdown-cart .cart-icon.cart-active {
      border-color: #A5C6CB; }
    .dropdown-cart .cart-icon:hover {
      cursor: pointer; }
    .dropdown-cart .cart-icon .cart-item-count {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: -8px;
      right: -8px;
      background-color: #A6C6CC;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 14px;
      padding: 5px 0;
      font-weight: 300;
      text-align: center;
      border-radius: 100%;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none; }
  .dropdown-cart .dropdown-menu {
    position: absolute;
    left: auto;
    right: 0;
    top: 125px;
    margin: 0;
    width: 350px;
    border-radius: 0 0 20px 20px;
    padding: 20px;
    border-top: 0;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE; }
    .dropdown-cart .dropdown-menu .cart-header {
      position: relative;
      color: #898989;
      font-size: 13px;
      line-height: 21px;
      padding: 3px;
      font-weight: 300;
      text-align: center;
      text-transform: uppercase; }
    .dropdown-cart .dropdown-menu .cart-items {
      position: relative;
      color: #898989;
      font-size: 13px;
      line-height: 17px;
      font-weight: 300;
      text-align: center; }
      .dropdown-cart .dropdown-menu .cart-items .cart-item {
        position: relative;
        border-bottom: 1px solid #898989;
        padding: 20px 0 20px 0; }
        .dropdown-cart .dropdown-menu .cart-items .cart-item .cart-item-inner {
          position: relative; }
          .dropdown-cart .dropdown-menu .cart-items .cart-item .cart-item-inner .cart-item-image {
            display: block;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            text-decoration: none; }
            .dropdown-cart .dropdown-menu .cart-items .cart-item .cart-item-inner .cart-item-image:hover {
              text-decoration: none; }
          .dropdown-cart .dropdown-menu .cart-items .cart-item .cart-item-inner .cart-item-data {
            font-size: 13px;
            line-height: 1.4;
            z-index: 1;
            position: relative;
            min-height: 100px;
            padding: 10px 0 10px 100px;
            text-align: right; }
      .dropdown-cart .dropdown-menu .cart-items .cart-totals {
        padding-top: 20px;
        padding-bottom: 20px; }
        .dropdown-cart .dropdown-menu .cart-items .cart-totals table {
          border: 0;
          width: 100%;
          line-height: 1.4;
          padding: 0; }
          .dropdown-cart .dropdown-menu .cart-items .cart-totals table .cart-total-row td {
            padding-top: 10px; }
    .dropdown-cart .dropdown-menu .cart-button {
      position: relative; }
    .dropdown-cart .dropdown-menu .divider {
      background-color: #898989;
      margin: 15px 0 0 0; }

div.points {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 41px; }
  div.points .points-label,
  div.points .points-value {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    font-size: 17px;
    line-height: 17px;
    color: #005B7F;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 0;
    border-radius: 0;
    padding: 12px; }
  div.points .points-value {
    color: #FFFFFF;
    background-color: #005B7F; }

nav.search {
  position: absolute;
  right: 15px;
  top: 5px; }
  nav.search .nav li > a {
    padding: 5px 8px; }

div.menu {
  position: absolute;
  right: 15px;
  top: 5px; }
  div.menu .navbar-toggle {
    position: relative;
    margin: 0;
    right: 0;
    border-color: #ccc;
    background-color: #FFF; }
    div.menu .navbar-toggle .icon-bar {
      background-color: #333; }
  div.menu .navbar-toggle:focus,
  div.menu .navbar-toggle:hover {
    background-color: #FFF; }

.navbar {
  min-height: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #e7e7e7; }

.page-header {
  overflow: hidden; }
  .page-header h2 {
    margin-top: 0;
    font-weight: 300;
    color: #000000;
    font-size: 36px; }

.page-header-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .page-header-flex .flex-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .page-header-flex .flex-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: end;
    align-items: flex-end; }

section.container {
  background-color: #ffffff;
  padding: 20px; }

section .row .navbar-toggle {
  right: 0; }

section .row .navbar-default {
  margin-left: -15px;
  margin-right: -15px; }

.spacer-40 {
  position: relative;
  width: 100%;
  height: 40px; }

.container-home .row,
.container-promos .row {
  margin-right: -10px;
  margin-left: -10px; }
  .container-home .row .col-md-3,
  .container-home .row .col-md-6,
  .container-promos .row .col-md-3,
  .container-promos .row .col-md-6 {
    padding-left: 10px;
    padding-right: 10px; }

.container-home .row-slideshow,
.container-promos .row-slideshow {
  position: relative;
  padding-top: 0;
  padding-bottom: 20px; }

.container-home .row-categories,
.container-home .row-promos,
.container-promos .row-categories,
.container-promos .row-promos {
  position: relative;
  padding-top: 20px;
  padding-bottom: 0; }
  .container-home .row-categories .category-wrap,
  .container-home .row-promos .category-wrap,
  .container-promos .row-categories .category-wrap,
  .container-promos .row-promos .category-wrap {
    display: block;
    position: relative;
    background-color: #EBEBEB;
    margin-bottom: 20px;
    text-decoration: none;
    overflow: hidden; }
    .container-home .row-categories .category-wrap .category-image-wrap,
    .container-home .row-promos .category-wrap .category-image-wrap,
    .container-promos .row-categories .category-wrap .category-image-wrap,
    .container-promos .row-promos .category-wrap .category-image-wrap {
      position: relative;
      overflow: hidden;
      border: 0;
      background-color: #E4EEEF; }
      .container-home .row-categories .category-wrap .category-image-wrap .category-image,
      .container-home .row-promos .category-wrap .category-image-wrap .category-image,
      .container-promos .row-categories .category-wrap .category-image-wrap .category-image,
      .container-promos .row-promos .category-wrap .category-image-wrap .category-image {
        transition: all 1.6s ease-in-out; }
    .container-home .row-categories .category-wrap .category-image,
    .container-home .row-promos .category-wrap .category-image,
    .container-promos .row-categories .category-wrap .category-image,
    .container-promos .row-promos .category-wrap .category-image {
      width: 100%;
      height: auto;
      padding-bottom: 100%;
      background-color: #FFFFFF;
      border: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center; }
    .container-home .row-categories .category-wrap:hover,
    .container-home .row-promos .category-wrap:hover,
    .container-promos .row-categories .category-wrap:hover,
    .container-promos .row-promos .category-wrap:hover {
      text-decoration: none; }
      .container-home .row-categories .category-wrap:hover .category-image-wrap .category-image,
      .container-home .row-promos .category-wrap:hover .category-image-wrap .category-image,
      .container-promos .row-categories .category-wrap:hover .category-image-wrap .category-image,
      .container-promos .row-promos .category-wrap:hover .category-image-wrap .category-image {
        transform: scale(1.05); }
    .container-home .row-categories .category-wrap .category-title,
    .container-home .row-promos .category-wrap .category-title,
    .container-promos .row-categories .category-wrap .category-title,
    .container-promos .row-promos .category-wrap .category-title {
      height: 130px;
      width: 100%;
      color: #464646;
      font-size: 21px;
      letter-spacing: 1px;
      line-height: 1.2;
      font-weight: normal;
      text-align: center;
      padding: 20px 15px 5px 15px; }
      .container-home .row-categories .category-wrap .category-title h3,
      .container-home .row-promos .category-wrap .category-title h3,
      .container-promos .row-categories .category-wrap .category-title h3,
      .container-promos .row-promos .category-wrap .category-title h3 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        letter-spacing: normal;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        min-height: 40px;
        text-transform: uppercase; }
        .container-home .row-categories .category-wrap .category-title h3.alternative,
        .container-home .row-promos .category-wrap .category-title h3.alternative,
        .container-promos .row-categories .category-wrap .category-title h3.alternative,
        .container-promos .row-promos .category-wrap .category-title h3.alternative {
          text-transform: capitalize !important; }
      .container-home .row-categories .category-wrap .category-title p,
      .container-home .row-promos .category-wrap .category-title p,
      .container-promos .row-categories .category-wrap .category-title p,
      .container-promos .row-promos .category-wrap .category-title p {
        margin: 0;
        padding: 0;
        font-size: 13px;
        letter-spacing: normal;
        line-height: 15px;
        font-weight: 300;
        text-align: center;
        min-height: 30px; }

.container-promos,
section.container-promos {
  padding-bottom: 0; }
  .container-promos .row-promos .category-wrap,
  section.container-promos .row-promos .category-wrap {
    display: block;
    position: relative;
    margin-bottom: 20px;
    background-color: transparent;
    z-index: 1; }
    .container-promos .row-promos .category-wrap::before,
    section.container-promos .row-promos .category-wrap::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      transition: all .4s ease-in-out;
      z-index: 2; }
    .container-promos .row-promos .category-wrap::after,
    section.container-promos .row-promos .category-wrap::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      opacity: 0;
      background-image: url("/themes/standard/images/icon-plus.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .4s ease-in-out;
      z-index: 3; }
    .container-promos .row-promos .category-wrap .category-title,
    section.container-promos .row-promos .category-wrap .category-title {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: start;
      align-items: flex-start;
      min-height: 0;
      min-width: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;
      height: 95px;
      padding: 15px 20px 10px 20px;
      background-color: rgba(255, 255, 255, 0.75);
      transition: all .4s ease-in-out;
      z-index: 4; }
      .container-promos .row-promos .category-wrap .category-title h3,
      section.container-promos .row-promos .category-wrap .category-title h3 {
        color: #000;
        text-align: left;
        font-weight: 500;
        width: 100%; }
      .container-promos .row-promos .category-wrap .category-title p,
      section.container-promos .row-promos .category-wrap .category-title p {
        color: #585758;
        text-align: left;
        font-weight: 300;
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
    .container-promos .row-promos .category-wrap:hover::before,
    section.container-promos .row-promos .category-wrap:hover::before {
      background-color: rgba(255, 255, 255, 0.75); }
    .container-promos .row-promos .category-wrap:hover::after,
    section.container-promos .row-promos .category-wrap:hover::after {
      opacity: 1; }
    .container-promos .row-promos .category-wrap:hover .category-title,
    section.container-promos .row-promos .category-wrap:hover .category-title {
      background-color: rgba(255, 255, 255, 0); }
    .container-promos .row-promos .category-wrap.promo-wrap::before,
    section.container-promos .row-promos .category-wrap.promo-wrap::before {
      content: none; }
    .container-promos .row-promos .category-wrap.promo-wrap::after,
    section.container-promos .row-promos .category-wrap.promo-wrap::after {
      content: none; }
    .container-promos .row-promos .category-wrap.promo-wrap:hover::before,
    section.container-promos .row-promos .category-wrap.promo-wrap:hover::before {
      content: none; }
    .container-promos .row-promos .category-wrap.promo-wrap:hover::after,
    section.container-promos .row-promos .category-wrap.promo-wrap:hover::after {
      content: none; }
  .container-promos .row-promos .col-md-6:nth-of-type(1) .row .col-md-12 .category-wrap .category-image,
  section.container-promos .row-promos .col-md-6:nth-of-type(1) .row .col-md-12 .category-wrap .category-image {
    padding-bottom: 100%; }
  .container-promos .row-promos .col-md-6:nth-of-type(2) .row .col-md-6 .category-wrap .category-image,
  section.container-promos .row-promos .col-md-6:nth-of-type(2) .row .col-md-6 .category-wrap .category-image {
    padding-bottom: calc(100% - 10px); }
  .container-promos .row-promos .col-md-6:nth-of-type(2) .row .col-md-12 .category-wrap .category-image,
  section.container-promos .row-promos .col-md-6:nth-of-type(2) .row .col-md-12 .category-wrap .category-image {
    padding-bottom: 50%;
    background-color: #E4EEEF; }
  .container-promos .row-promos .col-md-6 .row .col-md-12.col-big .category-wrap .category-image,
  section.container-promos .row-promos .col-md-6 .row .col-md-12.col-big .category-wrap .category-image {
    padding-bottom: 100%; }
  .container-promos .row-promos .col-md-6 .row .col-md-12 ~ .col-md-12.col-big .category-wrap .category-image,
  section.container-promos .row-promos .col-md-6 .row .col-md-12 ~ .col-md-12.col-big .category-wrap .category-image {
    padding-bottom: calc(100% - 10px); }
  .container-promos .row .col-md-12,
  .container-promos .row .col-md-6,
  .container-promos .row .col-md-3,
  section.container-promos .row .col-md-12,
  section.container-promos .row .col-md-6,
  section.container-promos .row .col-md-3 {
    padding-left: 10px;
    padding-right: 10px; }
  .container-promos .promo-content-wrap,
  section.container-promos .promo-content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    background-color: #E4EEEF;
    padding: 15px; }
    @media (min-width: 1440px) {
      .container-promos .promo-content-wrap,
      section.container-promos .promo-content-wrap {
        transform: translateY(-50%);
        top: 50%; } }
  .container-promos .promo-subheader,
  section.container-promos .promo-subheader {
    color: #9E9E9E;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 10px 0; }
  .container-promos .promo-header,
  section.container-promos .promo-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 10px 0; }
  .container-promos .promo-content,
  section.container-promos .promo-content {
    color: #808080;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 300;
    text-align: left;
    margin: 0 60px 10px 60px; }
    .container-promos .promo-content em,
    section.container-promos .promo-content em {
      color: #A4C3C8;
      font-weight: normal;
      font-style: normal;
      text-decoration: none; }

.container-nature,
section.container-nature {
  position: relative;
  padding-top: 0; }
  .container-nature .container-nature-wrap,
  section.container-nature .container-nature-wrap {
    position: relative;
    padding: 60px 80px 60px 80px;
    background-color: #F3E9E9;
    margin-bottom: 0;
    margin-left: calc(25% + 10px); }
  .container-nature .nature-icon,
  section.container-nature .nature-icon {
    position: absolute;
    display: block;
    top: 40px;
    right: 40px;
    z-index: 2;
    width: 60px;
    height: 60px;
    background-image: url("/themes/christmas/images/gaveuniverset-icon-sustainability.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
  .container-nature .nature-header,
  section.container-nature .nature-header {
    color: #656265;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: left;
    margin: 0 60px 40px 0;
    text-transform: uppercase; }
  .container-nature .nature-content,
  section.container-nature .nature-content {
    color: #656265;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 300;
    text-align: left;
    margin: 0;
    max-width: 950px; }
    .container-nature .nature-content p,
    section.container-nature .nature-content p {
      margin: 0; }
    .container-nature .nature-content p ~ p,
    section.container-nature .nature-content p ~ p {
      margin-top: 20px; }

.no-padding {
  padding: 0; }

.container-category .category-main-image {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 23%;
  background-color: #EBEBEB;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.container-category .category-main-title {
  margin: 60px 0 30px 0;
  font-weight: 300;
  font-size: 36px;
  text-transform: uppercase;
  color: #000000;
  text-align: center; }

.container-category .category-main-description {
  font-weight: 400;
  font-size: 14px;
  color: #585758;
  text-align: center;
  margin: 0 auto;
  max-width: 980px; }
  .container-category .category-main-description p {
    margin: 0; }
  .container-category .category-main-description p ~ p {
    margin-top: 1em; }

.container-category .category-main-description .description-desktop,
.container-category .vendor-main-description .description-desktop,
.vendor-category .category-main-description .description-desktop,
.vendor-category .vendor-main-description .description-desktop {
  display: block; }

.container-category .category-main-description .description-mobile,
.container-category .vendor-main-description .description-mobile,
.vendor-category .category-main-description .description-mobile,
.vendor-category .vendor-main-description .description-mobile {
  display: none; }

.container-category .row-header,
.container-home .row-header,
.container-vendor .row-header {
  position: relative;
  padding-bottom: 40px;
  background-color: #F5F5F5; }

.container-category .row-filter .filter-header,
.container-home .row-filter .filter-header,
.container-vendor .row-filter .filter-header {
  height: 60px;
  color: #626262;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 400;
  text-align: left; }

.container-category .row-filter .form-group,
.container-home .row-filter .form-group,
.container-vendor .row-filter .form-group {
  position: relative; }
  .container-category .row-filter .form-group .filter-group-header,
  .container-home .row-filter .form-group .filter-group-header,
  .container-vendor .row-filter .form-group .filter-group-header {
    position: relative;
    color: #626262;
    font-size: 13px;
    line-height: 20px;
    font-weight: 700;
    text-align: left;
    border-bottom: 1px solid #898989;
    padding-bottom: 5px;
    padding-right: 30px; }
    .container-category .row-filter .form-group .filter-group-header.toggle:hover,
    .container-home .row-filter .form-group .filter-group-header.toggle:hover,
    .container-vendor .row-filter .form-group .filter-group-header.toggle:hover {
      cursor: pointer; }
    .container-category .row-filter .form-group .filter-group-header .filter-group-toggle,
    .container-home .row-filter .form-group .filter-group-header .filter-group-toggle,
    .container-vendor .row-filter .form-group .filter-group-header .filter-group-toggle {
      position: absolute;
      top: 5px;
      right: 7px;
      height: 16px;
      width: 16px;
      background-image: url("/themes/standard/images/icon-triangle.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 0.3s ease;
      transform: rotate(0); }
  .container-category .row-filter .form-group ul,
  .container-home .row-filter .form-group ul,
  .container-vendor .row-filter .form-group ul {
    display: block;
    position: relative;
    margin: 0;
    list-style: none;
    padding: 20px 0 50px 0; }
    .container-category .row-filter .form-group ul li,
    .container-home .row-filter .form-group ul li,
    .container-vendor .row-filter .form-group ul li {
      overflow: hidden;
      display: block;
      position: relative;
      color: #626262;
      font-size: 13px;
      line-height: 1.2;
      font-weight: 400;
      text-align: left;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.3s ease; }
      .container-category .row-filter .form-group ul li .checkbox,
      .container-home .row-filter .form-group ul li .checkbox,
      .container-vendor .row-filter .form-group ul li .checkbox {
        padding: 0;
        margin-top: 1px;
        margin-bottom: 1px;
        height: auto;
        min-height: 0; }
        .container-category .row-filter .form-group ul li .checkbox.active,
        .container-home .row-filter .form-group ul li .checkbox.active,
        .container-vendor .row-filter .form-group ul li .checkbox.active {
          background-color: #EEEEEE; }
        .container-category .row-filter .form-group ul li .checkbox:hover, .container-category .row-filter .form-group ul li .checkbox:focus,
        .container-home .row-filter .form-group ul li .checkbox:hover,
        .container-home .row-filter .form-group ul li .checkbox:focus,
        .container-vendor .row-filter .form-group ul li .checkbox:hover,
        .container-vendor .row-filter .form-group ul li .checkbox:focus {
          background-color: #EEEEEE; }
        .container-category .row-filter .form-group ul li .checkbox .control-label,
        .container-home .row-filter .form-group ul li .checkbox .control-label,
        .container-vendor .row-filter .form-group ul li .checkbox .control-label {
          width: 100%;
          text-align: left;
          padding: 0;
          height: 26px;
          line-height: 26px; }
          .container-category .row-filter .form-group ul li .checkbox .control-label input,
          .container-home .row-filter .form-group ul li .checkbox .control-label input,
          .container-vendor .row-filter .form-group ul li .checkbox .control-label input {
            width: 1px;
            height: 1px;
            visibility: hidden;
            opacity: 0; }
      .container-category .row-filter .form-group ul li a,
      .container-home .row-filter .form-group ul li a,
      .container-vendor .row-filter .form-group ul li a {
        display: block;
        position: relative;
        padding: 0 0 0 4px;
        margin-top: 1px;
        margin-bottom: 1px;
        height: 26px;
        min-height: 0;
        width: 100%;
        text-align: left;
        line-height: 26px;
        color: #626262; }
        .container-category .row-filter .form-group ul li a:hover, .container-category .row-filter .form-group ul li a:focus,
        .container-home .row-filter .form-group ul li a:hover,
        .container-home .row-filter .form-group ul li a:focus,
        .container-vendor .row-filter .form-group ul li a:hover,
        .container-vendor .row-filter .form-group ul li a:focus {
          color: #626262;
          background-color: #EEEEEE;
          text-decoration: none; }
  .container-category .row-filter .form-group .filter-hidden,
  .container-home .row-filter .form-group .filter-hidden,
  .container-vendor .row-filter .form-group .filter-hidden {
    height: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .container-category .row-filter .form-group .filter-active .filter-group-toggle,
  .container-home .row-filter .form-group .filter-active .filter-group-toggle,
  .container-vendor .row-filter .form-group .filter-active .filter-group-toggle {
    transform: rotate(180deg); }
  .container-category .row-filter .form-group .filter-active .filter-hidden,
  .container-home .row-filter .form-group .filter-active .filter-hidden,
  .container-vendor .row-filter .form-group .filter-active .filter-hidden {
    height: 28px;
    padding-top: 0;
    padding-bottom: 0; }

.container-category .category-breadcrumb,
.container-category .vendor-breadcrumb,
.container-home .category-breadcrumb,
.container-home .vendor-breadcrumb,
.container-vendor .category-breadcrumb,
.container-vendor .vendor-breadcrumb {
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  color: #585758;
  font-size: 17px;
  line-height: 1.2;
  font-weight: 400;
  text-align: left;
  padding-top: 5px;
  margin: 0 -10px 0 -10px; }
  .container-category .category-breadcrumb span,
  .container-category .category-breadcrumb a,
  .container-category .vendor-breadcrumb span,
  .container-category .vendor-breadcrumb a,
  .container-home .category-breadcrumb span,
  .container-home .category-breadcrumb a,
  .container-home .vendor-breadcrumb span,
  .container-home .vendor-breadcrumb a,
  .container-vendor .category-breadcrumb span,
  .container-vendor .category-breadcrumb a,
  .container-vendor .vendor-breadcrumb span,
  .container-vendor .vendor-breadcrumb a {
    display: block;
    color: #585758;
    padding: 0 10px 0 10px; }
  .container-category .category-breadcrumb a:hover,
  .container-category .vendor-breadcrumb a:hover,
  .container-home .category-breadcrumb a:hover,
  .container-home .vendor-breadcrumb a:hover,
  .container-vendor .category-breadcrumb a:hover,
  .container-vendor .vendor-breadcrumb a:hover {
    color: #585758; }
  .container-category .category-breadcrumb .breadcrumb-arrow,
  .container-category .category-breadcrumb span.breadcrumb-arrow,
  .container-category .vendor-breadcrumb .breadcrumb-arrow,
  .container-category .vendor-breadcrumb span.breadcrumb-arrow,
  .container-home .category-breadcrumb .breadcrumb-arrow,
  .container-home .category-breadcrumb span.breadcrumb-arrow,
  .container-home .vendor-breadcrumb .breadcrumb-arrow,
  .container-home .vendor-breadcrumb span.breadcrumb-arrow,
  .container-vendor .category-breadcrumb .breadcrumb-arrow,
  .container-vendor .category-breadcrumb span.breadcrumb-arrow,
  .container-vendor .vendor-breadcrumb .breadcrumb-arrow,
  .container-vendor .vendor-breadcrumb span.breadcrumb-arrow {
    display: block;
    font-size: 0;
    height: 20px;
    width: 20px;
    opacity: 0.7;
    background-image: url("/themes/standard/images/icon-arrow-down.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(-90deg); }

.container-category .category-pagination,
.container-category .vendor-pagination,
.container-home .category-pagination,
.container-home .vendor-pagination,
.container-vendor .category-pagination,
.container-vendor .vendor-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 60px;
  color: #585758;
  font-size: 17px;
  line-height: 1.2;
  font-weight: 400;
  text-align: right;
  padding-top: 5px;
  margin: 0 -10px 0 -10px; }
  .container-category .category-pagination .resource-pagination,
  .container-category .vendor-pagination .resource-pagination,
  .container-home .category-pagination .resource-pagination,
  .container-home .vendor-pagination .resource-pagination,
  .container-vendor .category-pagination .resource-pagination,
  .container-vendor .vendor-pagination .resource-pagination {
    display: none;
    color: #585758;
    padding: 0 10px 0 10px; }

.container-category .product-pagination,
.container-home .product-pagination,
.container-vendor .product-pagination {
  margin-top: 15px;
  margin-bottom: 15px; }

.container-category .category-pagination-bottom,
.container-category .vendor-pagination-bottom,
.container-home .category-pagination-bottom,
.container-home .vendor-pagination-bottom,
.container-vendor .category-pagination-bottom,
.container-vendor .vendor-pagination-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center; }
  .container-category .category-pagination-bottom .pagination-dots,
  .container-category .vendor-pagination-bottom .pagination-dots,
  .container-home .category-pagination-bottom .pagination-dots,
  .container-home .vendor-pagination-bottom .pagination-dots,
  .container-vendor .category-pagination-bottom .pagination-dots,
  .container-vendor .vendor-pagination-bottom .pagination-dots {
    margin-top: 10px;
    margin-left: 10px; }
  .container-category .category-pagination-bottom a,
  .container-category .category-pagination-bottom span,
  .container-category .vendor-pagination-bottom a,
  .container-category .vendor-pagination-bottom span,
  .container-home .category-pagination-bottom a,
  .container-home .category-pagination-bottom span,
  .container-home .vendor-pagination-bottom a,
  .container-home .vendor-pagination-bottom span,
  .container-vendor .category-pagination-bottom a,
  .container-vendor .category-pagination-bottom span,
  .container-vendor .vendor-pagination-bottom a,
  .container-vendor .vendor-pagination-bottom span {
    display: -ms-flexbox;
    display: flex;
    min-width: 0;
    min-height: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    height: 30px;
    font-size: 18px;
    line-height: 18px;
    color: #898989;
    text-decoration: none;
    margin-left: 10px; }
    .container-category .category-pagination-bottom a:first-child,
    .container-category .category-pagination-bottom span:first-child,
    .container-category .vendor-pagination-bottom a:first-child,
    .container-category .vendor-pagination-bottom span:first-child,
    .container-home .category-pagination-bottom a:first-child,
    .container-home .category-pagination-bottom span:first-child,
    .container-home .vendor-pagination-bottom a:first-child,
    .container-home .vendor-pagination-bottom span:first-child,
    .container-vendor .category-pagination-bottom a:first-child,
    .container-vendor .category-pagination-bottom span:first-child,
    .container-vendor .vendor-pagination-bottom a:first-child,
    .container-vendor .vendor-pagination-bottom span:first-child {
      margin-left: 0; }
    .container-category .category-pagination-bottom a:focus, .container-category .category-pagination-bottom a:hover,
    .container-category .category-pagination-bottom span:focus,
    .container-category .category-pagination-bottom span:hover,
    .container-category .vendor-pagination-bottom a:focus,
    .container-category .vendor-pagination-bottom a:hover,
    .container-category .vendor-pagination-bottom span:focus,
    .container-category .vendor-pagination-bottom span:hover,
    .container-home .category-pagination-bottom a:focus,
    .container-home .category-pagination-bottom a:hover,
    .container-home .category-pagination-bottom span:focus,
    .container-home .category-pagination-bottom span:hover,
    .container-home .vendor-pagination-bottom a:focus,
    .container-home .vendor-pagination-bottom a:hover,
    .container-home .vendor-pagination-bottom span:focus,
    .container-home .vendor-pagination-bottom span:hover,
    .container-vendor .category-pagination-bottom a:focus,
    .container-vendor .category-pagination-bottom a:hover,
    .container-vendor .category-pagination-bottom span:focus,
    .container-vendor .category-pagination-bottom span:hover,
    .container-vendor .vendor-pagination-bottom a:focus,
    .container-vendor .vendor-pagination-bottom a:hover,
    .container-vendor .vendor-pagination-bottom span:focus,
    .container-vendor .vendor-pagination-bottom span:hover {
      color: #464646;
      text-decoration: none; }
    .container-category .category-pagination-bottom a.pagination-current,
    .container-category .category-pagination-bottom span.pagination-current,
    .container-category .vendor-pagination-bottom a.pagination-current,
    .container-category .vendor-pagination-bottom span.pagination-current,
    .container-home .category-pagination-bottom a.pagination-current,
    .container-home .category-pagination-bottom span.pagination-current,
    .container-home .vendor-pagination-bottom a.pagination-current,
    .container-home .vendor-pagination-bottom span.pagination-current,
    .container-vendor .category-pagination-bottom a.pagination-current,
    .container-vendor .category-pagination-bottom span.pagination-current,
    .container-vendor .vendor-pagination-bottom a.pagination-current,
    .container-vendor .vendor-pagination-bottom span.pagination-current {
      color: #464646;
      text-decoration: none;
      margin-left: 10px; }
      .container-category .category-pagination-bottom a.pagination-current:focus, .container-category .category-pagination-bottom a.pagination-current:hover,
      .container-category .category-pagination-bottom span.pagination-current:focus,
      .container-category .category-pagination-bottom span.pagination-current:hover,
      .container-category .vendor-pagination-bottom a.pagination-current:focus,
      .container-category .vendor-pagination-bottom a.pagination-current:hover,
      .container-category .vendor-pagination-bottom span.pagination-current:focus,
      .container-category .vendor-pagination-bottom span.pagination-current:hover,
      .container-home .category-pagination-bottom a.pagination-current:focus,
      .container-home .category-pagination-bottom a.pagination-current:hover,
      .container-home .category-pagination-bottom span.pagination-current:focus,
      .container-home .category-pagination-bottom span.pagination-current:hover,
      .container-home .vendor-pagination-bottom a.pagination-current:focus,
      .container-home .vendor-pagination-bottom a.pagination-current:hover,
      .container-home .vendor-pagination-bottom span.pagination-current:focus,
      .container-home .vendor-pagination-bottom span.pagination-current:hover,
      .container-vendor .category-pagination-bottom a.pagination-current:focus,
      .container-vendor .category-pagination-bottom a.pagination-current:hover,
      .container-vendor .category-pagination-bottom span.pagination-current:focus,
      .container-vendor .category-pagination-bottom span.pagination-current:hover,
      .container-vendor .vendor-pagination-bottom a.pagination-current:focus,
      .container-vendor .vendor-pagination-bottom a.pagination-current:hover,
      .container-vendor .vendor-pagination-bottom span.pagination-current:focus,
      .container-vendor .vendor-pagination-bottom span.pagination-current:hover {
        cursor: default;
        color: #464646;
        text-decoration: none; }

.container-category .row-specifications,
.container-home .row-specifications,
.container-vendor .row-specifications {
  padding-top: 80px;
  padding-bottom: 80px; }
  .container-category .row-specifications .content-specifications,
  .container-home .row-specifications .content-specifications,
  .container-vendor .row-specifications .content-specifications {
    font-weight: 400;
    font-size: 14px;
    color: #585758;
    margin: 0; }
    .container-category .row-specifications .content-specifications p,
    .container-home .row-specifications .content-specifications p,
    .container-vendor .row-specifications .content-specifications p {
      margin: 0; }
    .container-category .row-specifications .content-specifications p ~ p,
    .container-home .row-specifications .content-specifications p ~ p,
    .container-vendor .row-specifications .content-specifications p ~ p {
      margin-top: 1em; }

.container-vendor .vendor-main-title {
  margin: 40px 0 30px 0;
  font-weight: 300;
  font-size: 36px;
  text-transform: uppercase;
  color: #000000;
  text-align: center; }

.container-vendor .vendor-main-description {
  font-weight: 400;
  font-size: 14px;
  color: #585758;
  text-align: center;
  margin: 0 auto;
  max-width: 980px; }
  .container-vendor .vendor-main-description p {
    margin: 0; }
  .container-vendor .vendor-main-description p ~ p {
    margin-top: 1em; }

@media (max-width: 767px) {
  .coupon-label {
    font-size: 13px !important; }
  .btn-lg {
    min-width: 160px !important;
    height: 45px !important;
    font-size: 18px !important;
    line-height: 18px !important; }
  .container-cart .cart-proceed-row .form-group input[type="text"] {
    height: 40px !important;
    font-size: 19px !important;
    line-height: 19px !important; } }

.container-cart .page-header {
  min-height: 150px;
  background-color: #EBEBEB;
  padding: 20px;
  background-image: url("/themes/standard/images/icon-basket-big.png");
  background-repeat: no-repeat;
  background-size: 100px 100px;
  background-position: right 40px center;
  margin: 0 0 20px 0; }
  .container-cart .page-header h2 {
    color: #000000;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    margin: 30px 0 0 0; }
  .container-cart .page-header p {
    color: #464646;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
    margin: 30px 0 0 0; }

.container-cart .cart-proceed-row [class*="col-"] ~ [class*="col-"] {
  padding-left: 10px; }

.container-cart .cart-proceed-row [class*="col-"]:first-child {
  padding-right: 10px; }

.container-cart .cart-proceed-row label {
  color: #919191;
  font-size: 25px;
  line-height: 25px;
  font-weight: 700;
  text-align: left; }

.container-cart .cart-proceed-row .form-group {
  float: left; }
  .container-cart .cart-proceed-row .form-group input[type="text"] {
    border-radius: 14px;
    border: 1px solid #cccccc;
    height: 50px;
    color: #585758;
    font-size: 19px;
    line-height: 19px;
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 300px; }

.cart-product-image {
  display: block;
  float: left;
  width: 150px;
  height: 150px;
  min-width: 158px;
  border: 1px solid #b7b7b7;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-right: 40px; }

.quantity-wrap {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 131px;
  height: 39px;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .quantity-wrap .quantity-add {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 39px;
    height: 39px;
    margin: 0;
    float: left;
    background-color: #A4C3C8;
    color: #FFFFFF;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    padding: 7px 6px 5px 6px;
    transition: all .2s ease-in-out; }
    .quantity-wrap .quantity-add:hover {
      background-color: #6EA9B3;
      color: #FFFFFF; }
    .quantity-wrap .quantity-add .fa {
      margin-top: 1px; }
  .quantity-wrap .quantity-inner {
    display: inline-block;
    width: auto;
    min-width: 39px;
    height: 39px;
    margin: 0 46px 0 46px;
    float: left;
    border: 1px solid #000002;
    color: #464646;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    padding: 6px; }
  .quantity-wrap .quantity-remove {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 39px;
    height: 39px;
    margin: 0;
    float: left;
    background-color: #A4C3C8;
    color: #ffffff;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    padding: 5px 6px 7px 6px;
    transition: all .2s ease-in-out; }
    .quantity-wrap .quantity-remove:hover {
      background-color: #6EA9B3;
      color: #FFFFFF; }
    .quantity-wrap .quantity-remove .fa {
      margin-top: 3px; }

.quantity-destroy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  margin: 0;
  background-color: #ACACAC;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
  transition: all .2s ease-in-out; }
  .quantity-destroy:hover {
    background-color: #878787;
    color: #FFFFFF; }
  .quantity-destroy .fa {
    color: #fff;
    margin-top: -2px; }

.row-popular {
  border-top: 0;
  padding-top: 60px;
  padding-bottom: 40px; }
  .row-popular .popular-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 60px 0; }

.row-newest {
  border-top: 0;
  padding-top: 60px;
  padding-bottom: 40px; }
  .row-newest .newest-subheader {
    color: #9E9E9E;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 10px 0; }
  .row-newest .newest-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 60px 0; }

.row-products {
  border-top: 0;
  padding-top: 20px;
  padding-bottom: 40px; }
  .row-products .products-subheader {
    color: #9E9E9E;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 10px 0; }
  .row-products .products-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 60px 0; }
  .row-products .product-scroller {
    padding-left: 0;
    padding-right: 0; }

.product-list-end {
  position: relative; }

.flag {
  color: #333;
  font-size: 12px;
  padding: 7px 5px 7px 5px !important; }

div.dropdown {
  text-align: right; }

.flag:before {
  background: url(../images/flags.png) no-repeat left center;
  width: 16px;
  height: 11px;
  content: " ";
  display: inline-block;
  position: relative; }

li.active a {
  font-weight: bold; }

.jumbotron {
  background: #f5f5f5 !important; }

.flag.flag-de:before {
  background-position: -16px 0; }

.flag.flag-da:before {
  background-position: -32px 0; }

.flag.flag-es:before {
  background-position: 0 -11px; }

.flag.flag-fr:before {
  background-position: -16px -11px; }

.flag.flag-en:before {
  background-position: -32px -11px; }

.flag.flag-no:before {
  background-position: 0 -22px; }

.flag.flag-se:before {
  background-position: -16px -22px; }

.primary {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0 0 100% 0;
  border: 1px solid #DDDDDD; }
  .primary a.fancybox {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
  .primary > a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
    .primary > a > .fa {
      font-size: 9em;
      line-height: 1;
      color: #000;
      opacity: 0.8;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 3;
      transform: translateY(-50%) translateX(-50%); }
  .primary .product-sustainability {
    width: 50px;
    height: 50px;
    left: 15px;
    bottom: 15px; }
  .primary .product-exclusivity {
    width: 209px; }

.thumbs {
  position: relative;
  padding-top: 15px; }
  .thumbs a {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding: 0 0 100% 0;
    border: 1px solid #DDDDDD;
    margin-top: 15px;
    margin-bottom: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    .thumbs a:hover {
      cursor: pointer; }
    .thumbs a > .fa {
      font-size: 3em;
      line-height: 1;
      color: #000;
      opacity: 0.8;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 3;
      transform: translateY(-50%) translateX(-50%); }

.sold-out {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(235, 235, 235, 0.8);
  top: 0;
  z-index: 999;
  cursor: pointer; }

.sold-out span {
  margin-top: 100px;
  display: block;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px; }

.aside {
  position: absolute;
  top: 39px;
  right: 15px;
  min-width: 200px; }

.aside .media-object {
  width: 46px;
  height: 46px;
  padding: 0;
  text-align: center;
  overflow: hidden; }
  .aside .media-object img {
    width: auto;
    height: auto;
    max-width: 46px;
    max-height: 46px; }

.aside .dropdown-toggle {
  line-height: 46px;
  margin-left: 8px; }

.aside .dropdown-toggle:hover,
.aside .dropdown-toggle:focus {
  text-decoration: none; }

.navbar-brand {
  width: 255px;
  font-size: 14px;
  display: none; }

.navbar-brand img {
  width: 30px;
  height: 30px; }

.navbar-brand .dropdown-toggle {
  line-height: 30px;
  margin-left: 5px; }

.navbar-brand .dropdown, .navbar-brand img {
  margin-top: -5px; }

.nav-justified .glyphicon {
  position: absolute;
  top: 13px;
  color: #AAAAAA;
  right: -5px; }

.carousel .carousel-control > span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: 30px;
  height: 30px;
  font-size: 30px;
  line-height: 30px; }

.nav-justified a:hover {
  background-color: inherit !important; }

th.tr {
  text-align: right; }

th.tc {
  text-align: center; }

h5 {
  font-size: 16px; }

.acceptedCards {
  position: relative;
  top: 2px;
  left: 5px; }

.acceptedCards .card {
  display: inline-block;
  width: 25px;
  height: 16px;
  text-indent: -9999px;
  margin-right: 1px;
  background: url("/themes/standard/images/cards.png") no-repeat; }
  .acceptedCards .card.visa {
    background-position: 0 -16px; }
  .acceptedCards .card.master {
    background-position: 0 -32px; }
  .acceptedCards .card.amex {
    background-position: 0 -48px; }
  .acceptedCards .card.maestro {
    background-position: 0 -64px; }
  .acceptedCards .card.diners {
    background-position: 0 -80px; }
  .acceptedCards .card.jcb {
    background-position: 0 -96px; }
  .acceptedCards .card.dankort {
    background-position: 0 -112px; }
  .acceptedCards .card.forbrug {
    background-position: 0 -128px; }
  .acceptedCards .card.union {
    background-position: 0 -144px; }
  .acceptedCards .card.electron {
    background-position: 0 -16px; }

.retail-price {
  font-size: 12px;
  color: #AAAAAA;
  margin-top: 0;
  display: block; }
  .retail-price span {
    text-decoration: line-through; }

.price {
  display: block;
  margin: 0 0 10px 0;
  font-weight: bold;
  font-size: 16px; }

.product-scroller {
  padding-left: 5px;
  padding-right: 5px; }

.product-item {
  padding-left: 10px;
  padding-right: 10px; }
  .product-item .thumbnail {
    transition: all .2s ease-in-out;
    transform: translateY(0); }
    .product-item .thumbnail:hover {
      transform: translateY(-7px);
      box-shadow: 0 4px 6px rgba(50, 50, 50, 0.12), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .product-item .product-sold-out,
  .product-item .product-in-stock {
    color: #AAAAAA; }
    .product-item .product-sold-out:focus, .product-item .product-sold-out:hover,
    .product-item .product-in-stock:focus,
    .product-item .product-in-stock:hover {
      color: #AAAAAA; }
  .product-item.out-of-stock .product-thumbnail {
    opacity: 0.5; }
  .product-item.out-of-stock .product-thumbnail-1 {
    opacity: 0.5; }
  .product-item.out-of-stock .product-thumbnail-2 {
    opacity: 0; }
  .product-item.out-of-stock .details strong {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px; }

.product-item .thumbnail.thumbnail-separator {
  position: relative;
  padding-bottom: 30.90%;
  min-height: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  transform: translateY(0);
  box-shadow: none; }
  .product-item .thumbnail.thumbnail-separator:hover {
    transform: translateY(0);
    box-shadow: none; }

.details .product-name {
  display: block;
  overflow: hidden;
  height: 40px;
  margin-bottom: 3px; }

.row-other .thumbnail .details div {
  overflow: hidden; }

.glsPakkeshopWrap {
  display: none;
  padding: 10px 0 10px; }

.glsPakkeshopWrap.active {
  display: block; }

.yoyoServicePointsWrap {
  display: none;
  padding: 10px 0 10px; }
  .yoyoServicePointsWrap.active {
    display: block; }

.navbar-toggle {
  position: absolute;
  right: 15px;
  z-index: 100; }

.navbar-default {
  overflow: hidden;
  background-color: transparent;
  margin-top: 15px;
  padding-bottom: 0;
  min-height: 85px; }

.navbar-collapse.collapsing,
.navbar-collapse.in {
  padding-bottom: 0;
  min-height: 85px;
  border-top: 0; }

.navbar-collapse.in {
  height: 100%; }

.navbar-collapse {
  max-height: none; }

.nav > li > a {
  padding: 8px; }

section.container .navbar-default {
  background: white; }

.page-wrap h1 {
  font-weight: 300;
  font-size: 36px;
  color: #000000;
  margin: 0 0 20px 0;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

.page-wrap h2 {
  font-weight: 300;
  font-size: 36px;
  color: #000000;
  margin: 0 0 20px 0;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

.page-wrap h5 {
  font-weight: 300;
  font-size: 16px;
  color: #585758;
  margin: 0 0 20px 0; }

.page-wrap p {
  font-weight: 400;
  font-size: 13px;
  color: #585758;
  margin: 0; }

.page-wrap p ~ p {
  margin-top: 20px; }

.page-wrap .alert {
  margin: 0;
  border-radius: 0; }
  .page-wrap .alert p {
    color: inherit; }

.page-special-wrap .container-fluid > .container {
  padding-left: 0;
  padding-right: 0; }

.page-special-wrap .page-header-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center; }

.page-special-wrap .page-content {
  padding: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 490px;
  background-color: transparent; }
  .page-special-wrap .page-content h1,
  .page-special-wrap .page-content h2,
  .page-special-wrap .page-content h5,
  .page-special-wrap .page-content p {
    max-width: 750px; }

.page-special-wrap .row-price {
  padding-top: 20px;
  padding-bottom: 20px; }
  .page-special-wrap .row-price .price-subheader {
    color: #9E9E9E;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 10px 0; }
  .page-special-wrap .row-price .price-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 20px 0; }
  .page-special-wrap .row-price .price-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .page-special-wrap .row-price .price-flex .price-flex-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 150px;
      height: 60px;
      border: 2px solid #ECECEC;
      border-radius: 0;
      background-color: #FFFFFF;
      color: #000000;
      margin: 0 5px 0 5px;
      font-size: 14px;
      transition: all .2s ease-in-out; }
      .page-special-wrap .row-price .price-flex .price-flex-item a {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #000000; }
        .page-special-wrap .row-price .price-flex .price-flex-item a:hover {
          text-decoration: none; }
      .page-special-wrap .row-price .price-flex .price-flex-item:hover {
        cursor: pointer;
        border: 2px solid #6EA9B3;
        background-color: #6EA9B3;
        color: #FFFFFF; }
        .page-special-wrap .row-price .price-flex .price-flex-item:hover a {
          color: #FFFFFF; }
    .page-special-wrap .row-price .price-flex .price-flex-item:first-of-type {
      border: 2px solid #A5C6CB;
      background-color: #A5C6CB;
      color: #FFFFFF; }
      .page-special-wrap .row-price .price-flex .price-flex-item:first-of-type a {
        color: #FFFFFF; }
      .page-special-wrap .row-price .price-flex .price-flex-item:first-of-type:hover {
        border: 2px solid #6EA9B3;
        background-color: #6EA9B3;
        color: #FFFFFF; }
        .page-special-wrap .row-price .price-flex .price-flex-item:first-of-type:hover a {
          color: #FFFFFF; }

.page-special-wrap .container-page-footer {
  padding: 40px 0 60px 0;
  text-align: center; }
  .page-special-wrap .container-page-footer h2 {
    font-weight: 300;
    font-size: 26px;
    color: #000000;
    margin: 0 0 20px 0;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
  .page-special-wrap .container-page-footer h5 {
    font-weight: 300;
    font-size: 16px;
    color: #585758;
    margin: 0 0 20px 0; }
  .page-special-wrap .container-page-footer div,
  .page-special-wrap .container-page-footer p {
    font-weight: 400;
    font-size: 13px;
    color: #585758;
    margin: 0; }
  .page-special-wrap .container-page-footer p ~ p {
    margin-top: 20px; }

.container-content .row-content {
  padding-top: 10px;
  padding-bottom: 10px; }
  .container-content .row-content .flex-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    min-height: 500px;
    margin-left: -10px;
    margin-right: -10px; }
    .container-content .row-content .flex-content .flex-image {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #FFFFFF;
      margin: 10px;
      padding: 20px;
      border: 1px solid transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      .container-content .row-content .flex-content .flex-image.flex-image-contain {
        background-size: contain; }
    .container-content .row-content .flex-content .flex-text {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: transparent;
      margin: 10px;
      padding: 20px;
      border: 1px solid transparent; }
      .container-content .row-content .flex-content .flex-text h2 {
        font-weight: 300;
        font-size: 26px;
        color: #FFFFFF;
        margin: 0 0 20px 0;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto; }
      .container-content .row-content .flex-content .flex-text h5 {
        font-weight: 300;
        font-size: 16px;
        color: #FFFFFF;
        margin: 0 0 20px 0; }
      .container-content .row-content .flex-content .flex-text div,
      .container-content .row-content .flex-content .flex-text p {
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
        margin: 0; }
      .container-content .row-content .flex-content .flex-text p ~ p {
        margin-top: 20px; }
      .container-content .row-content .flex-content .flex-text.color-1 {
        background-color: #F3E9E8;
        border-color: #F3E9E8; }
        .container-content .row-content .flex-content .flex-text.color-1 h2,
        .container-content .row-content .flex-content .flex-text.color-1 h5,
        .container-content .row-content .flex-content .flex-text.color-1 p {
          color: #656265; }
        .container-content .row-content .flex-content .flex-text.color-1 .category-link a {
          color: #656265; }
          .container-content .row-content .flex-content .flex-text.color-1 .category-link a:hover {
            color: #656265; }
      .container-content .row-content .flex-content .flex-text.color-2 {
        background-color: #CCA7A6;
        border-color: #CCA7A6; }
        .container-content .row-content .flex-content .flex-text.color-2 .category-link a {
          color: #FFFFFF; }
          .container-content .row-content .flex-content .flex-text.color-2 .category-link a:hover {
            color: #FFFFFF; }
      .container-content .row-content .flex-content .flex-text .category-link {
        position: relative;
        width: 100%;
        text-align: right;
        padding: 20px 40px 0 0; }
        .container-content .row-content .flex-content .flex-text .category-link a {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          height: 32px;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: end;
          justify-content: flex-end; }
          .container-content .row-content .flex-content .flex-text .category-link a .fa {
            margin-left: 7px;
            font-size: 18px;
            line-height: 18px;
            transition: transform .2s ease-in-out;
            transform: translateX(0); }
          .container-content .row-content .flex-content .flex-text .category-link a:hover {
            text-decoration: none;
            cursor: pointer; }
            .container-content .row-content .flex-content .flex-text .category-link a:hover .fa {
              transform: translateX(5px); }
  .container-content .row-content .flex-content:nth-of-type(odd) {
    -ms-flex-direction: row;
    flex-direction: row; }
  .container-content .row-content .flex-content:nth-of-type(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }

@media only screen and (max-width: 991px) {
  .thumbnail {
    min-height: 270px; } }

@media only screen and (min-width: 991px) {
  .thumbnail {
    min-height: 350px; } }

@media only screen and (max-width: 400px) {
  .thumbnail {
    min-height: 0; }
  .navbar-default {
    padding-bottom: 0; } }

@media print {
  a[href]:after {
    content: none !important; } }

.container-carousel {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: #FFFFFF;
  position: relative;
  z-index: 1; }
  .container-carousel > .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0; }
    .container-carousel > .row > .col-md-12 {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0; }
  .container-carousel .carousel .carousel-control {
    width: 10%;
    max-width: 200px;
    min-width: 100px; }
  @media screen and (min-width: 768px) {
    .container-carousel .carousel .carousel-indicators {
      bottom: 55px; } }
  .container-carousel .slide-widget {
    z-index: 2;
    position: absolute;
    top: calc(50% + 88px);
    left: 0;
    right: 0;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    transition: all .2s ease-in-out; }
    .container-carousel .slide-widget .slide-widget-top-text {
      font-weight: 300;
      font-size: 16px;
      margin: 0 0 10px 0;
      color: #FFFFFF;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 440px; }
      .container-carousel .slide-widget .slide-widget-top-text.alternative {
        display: none; }
    .container-carousel .slide-widget .slide-widget-text {
      font-weight: 300;
      letter-spacing: 2px;
      font-size: 40px;
      margin: 0 0 40px 0;
      color: #FFFFFF;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 440px; }
      .container-carousel .slide-widget .slide-widget-text.alternative {
        max-width: none;
        font-weight: 700; }
        @media (min-width: 1000px) {
          .container-carousel .slide-widget .slide-widget-text.alternative {
            font-size: 60px; } }
    .container-carousel .slide-widget .slide-widget-button {
      display: inline-block;
      background-color: #A4C3C8;
      color: #FFFFFF;
      box-shadow: none;
      border-radius: 0;
      font-weight: normal;
      min-width: 230px;
      padding: 15px;
      margin-left: 20px;
      margin-right: 20px;
      transition: all .2s ease-in-out; }
      .container-carousel .slide-widget .slide-widget-button.alternative {
        font-weight: 600;
        background-color: #ffe700;
        color: #0099da; }
      .container-carousel .slide-widget .slide-widget-button:focus, .container-carousel .slide-widget .slide-widget-button:hover, .container-carousel .slide-widget .slide-widget-button:active {
        text-decoration: none;
        background-color: #6EA9B3;
        color: #FFFFFF; }
  .container-carousel .carousel-arrow-down {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 70px;
    height: 70px;
    border-radius: 100%;
    z-index: 2;
    background-color: #FFFFFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/themes/standard/images/icon-arrow-down.png"); }
    .container-carousel .carousel-arrow-down:hover {
      cursor: pointer; }
    .container-carousel .carousel-arrow-down.animation-bounce {
      animation: bounce 4s linear infinite 2s; }

.carousel-inner > .item > a > img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.carousel-inner .item a {
  display: block;
  position: relative;
  color: inherit;
  text-decoration: none; }

.carousel-inner .item img {
  margin-left: auto;
  margin-right: auto; }

.carousel-inner .carousel-caption a {
  display: block;
  position: relative; }

.cart-proceed-row a.btn,
.confirm-proceed-row a.btn {
  margin-top: 15px;
  margin-bottom: 15px; }

.cart-proceed-row .form-group,
.confirm-proceed-row .form-group {
  margin-top: 15px;
  margin-bottom: 15px; }

.thumbnail {
  border: 1px solid #EBEBEB;
  background-color: #FFFFFF;
  position: relative;
  text-align: center;
  padding: 0;
  border-radius: 0; }
  .thumbnail.alternative a {
    transition: all 0.5s ease-in-out; }
  .thumbnail.alternative a:hover {
    transform: scale(1.05);
    opacity: 0.6; }
  .thumbnail a:hover {
    color: #A6C6CC;
    text-decoration: none; }
  .thumbnail > a {
    display: block;
    position: relative; }
  .thumbnail .product-thumbnail {
    width: 100%;
    height: 1px;
    padding: 0 0 100% 0;
    background-color: #FFFFFF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  .thumbnail .product-thumbnail-1 {
    transition: opacity 0.1s ease;
    opacity: 1; }
  .thumbnail .product-thumbnail-2 {
    transition: opacity 0.1s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
  .thumbnail:hover .product-thumbnail-1 {
    opacity: 0; }
  .thumbnail:hover .product-thumbnail-2 {
    opacity: 1; }
  .thumbnail .pricing {
    min-height: 45px; }
  .thumbnail .details {
    position: relative;
    display: block;
    overflow: hidden;
    height: 75px;
    margin: 10px 5px;
    color: #464646; }
    .thumbnail .details .vendor {
      position: relative;
      display: none;
      height: 15px;
      color: #333;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .thumbnail .product-details {
    position: relative;
    padding: 10px 15px 10px 15px;
    min-height: 70px; }
    .thumbnail .product-details .product-title {
      position: relative;
      color: #464646;
      font-size: 13px;
      line-height: 17px;
      font-weight: 400;
      text-align: center;
      letter-spacing: 0;
      padding-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .thumbnail .product-details .product-sale-price {
      position: relative;
      color: #464646;
      font-size: 13px;
      line-height: 21px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0;
      padding-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .thumbnail .product-details .product-retail-price {
      position: relative;
      color: #898989;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      text-align: center;
      letter-spacing: 0;
      padding-bottom: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .thumbnail .product-details .product-inventory {
      position: relative;
      color: #898989;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      text-align: center;
      letter-spacing: 0;
      padding-bottom: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .thumbnail .product-buttons {
    position: relative;
    padding: 0 15px 10px 15px;
    height: 60px;
    opacity: 0.4; }
    .thumbnail .product-buttons a {
      display: block;
      width: auto; }
    .thumbnail .product-buttons .product-button-half {
      position: relative;
      width: 50%;
      float: left; }
      .thumbnail .product-buttons .product-button-half:first-child {
        padding-right: 7px; }
        .thumbnail .product-buttons .product-button-half:first-child a {
          text-decoration: none;
          background-color: #B7B7B7;
          color: #FFFFFF; }
          .thumbnail .product-buttons .product-button-half:first-child a:focus, .thumbnail .product-buttons .product-button-half:first-child a:hover, .thumbnail .product-buttons .product-button-half:first-child a:active {
            text-decoration: none;
            background-color: #A3A3A3;
            color: #FFFFFF; }
      .thumbnail .product-buttons .product-button-half:last-child {
        padding-left: 7px; }
        .thumbnail .product-buttons .product-button-half:last-child a {
          text-decoration: none;
          background-color: #A4C3C8;
          color: #FFFFFF; }
          .thumbnail .product-buttons .product-button-half:last-child a:focus, .thumbnail .product-buttons .product-button-half:last-child a:hover, .thumbnail .product-buttons .product-button-half:last-child a:active {
            text-decoration: none;
            background-color: #6EA9B3;
            color: #FFFFFF; }
      .thumbnail .product-buttons .product-button-half a {
        height: 40px;
        padding: 11px 3px 11px 3px;
        color: #FFFFFF;
        font-size: 13px;
        line-height: 18px;
        font-weight: normal;
        text-align: center;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        transition: all .2s ease-in-out; }
  .thumbnail:hover .product-buttons {
    opacity: 1; }

.row-relations h4 {
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center; }

.row-relations .thumbnail .details {
  min-height: 125px; }

.row-other h4 {
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center; }

.row-other .thumbnail .details {
  min-height: 125px; }

.row-product-content {
  margin-bottom: 65px; }

.row-other ~ .row-other,
.row-other ~ .row-relations {
  margin-top: 60px; }

.out-of-stock .thumbnail:hover .product-thumbnail-1 {
  opacity: 0; }

.out-of-stock .thumbnail:hover .product-thumbnail-2 {
  opacity: 0.5; }

.out-of-stock .thumbnail:hover .product-buttons {
  opacity: 0.5; }

.carousel-control.left, .carousel-control.right {
  background-image: none; }

.product-discount-ribbon-wrap {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 108px;
  height: 108px;
  overflow: hidden;
  z-index: 3; }
  .product-discount-ribbon-wrap::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    content: '';
    display: block;
    border: 2px solid #809FA5; }
  .product-discount-ribbon-wrap::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    display: block;
    border: 2px solid #809FA5; }
  .product-discount-ribbon-wrap .product-discount-ribbon {
    position: absolute;
    top: 20px;
    left: -60px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 200px;
    height: 40px;
    background-color: #A6C6CC;
    border-color: #A6C6CC;
    border-radius: 0;
    font-size: 16px;
    line-height: 16px;
    padding: 0;
    text-align: center;
    color: #FFFFFF;
    font-weight: 700;
    letter-spacing: 1px;
    transform: rotate(-45deg); }
    .product-discount-ribbon-wrap .product-discount-ribbon::before {
      content: '- '; }
    .product-discount-ribbon-wrap .product-discount-ribbon::after {
      content: ' %'; }

.product-new {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-color: #396369;
  border-color: #396369;
  border-radius: 100%;
  font-size: 17px;
  line-height: 25px;
  padding-top: 27px;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 3;
  font-weight: 700;
  letter-spacing: 1px; }

.product-new-ribbon-wrap {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 108px;
  height: 108px;
  overflow: hidden;
  z-index: 3; }
  .product-new-ribbon-wrap::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    content: '';
    display: block;
    border: 2px solid #809FA5; }
  .product-new-ribbon-wrap::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    display: block;
    border: 2px solid #809FA5; }
  .product-new-ribbon-wrap .product-new-ribbon {
    position: absolute;
    top: 20px;
    left: -60px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 200px;
    height: 40px;
    background-color: #A6C6CC;
    border-color: #A6C6CC;
    border-radius: 0;
    font-size: 16px;
    line-height: 16px;
    padding: 0;
    text-align: center;
    color: #FFFFFF;
    font-weight: 700;
    letter-spacing: 1px;
    transform: rotate(-45deg); }
    .product-new-ribbon-wrap .product-new-ribbon::before {
      content: ''; }
    .product-new-ribbon-wrap .product-new-ribbon::after {
      content: ''; }

.product-thumbnail-wrap {
  position: relative;
  background-color: #FFFFFF; }

.product-sustainability {
  display: inline-block;
  position: absolute;
  width: 35px;
  height: 35px;
  background-image: url("/themes/standard/images/icon-sustainability.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  left: 6px;
  bottom: 6px;
  z-index: 2; }

.product-sustainability-img {
  display: inline-block;
  position: absolute;
  width: 35px;
  height: 35px;
  background-image: url("/themes/standard/images/icon-sustainability.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2; }

:target {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden; }

.custom-designed-links {
  color: "";
  text-transform: uppercase;
  font-weight: 500; }

#product-specifications .modal-dialog {
  width: 70%; }

img {
  max-width: 100%; }

.preview {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px; } }

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px; }

.preview-thumbnail.nav-tabs li {
  width: 18%;
  margin-right: 2.5%; }

.preview-thumbnail.nav-tabs li img {
  max-width: 100%;
  display: block; }

.preview-thumbnail.nav-tabs li a {
  padding: 0;
  margin: 0; }

.preview-thumbnail.nav-tabs li:last-of-type {
  margin-right: 0; }

.tab-content {
  overflow: hidden; }

.tab-content img {
  width: 100%;
  animation-name: opacity;
  animation-duration: .3s; }

@media screen and (min-width: 997px) {
  .wrapper {
    display: -ms-flexbox;
    display: flex; } }

.details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.product-exclusivity {
  display: inline-block;
  position: absolute;
  height: 35px;
  line-height: 36px;
  right: 0;
  top: 10%;
  z-index: 2;
  color: #f0f0f0;
  background-color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 10px 0;
  font-weight: 500;
  transition: width 600ms, background-color .4s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  width: 90px; }
  .product-exclusivity span {
    color: #f0f0f0;
    margin-left: 10px;
    transition: margin-left 250ms; }

.product-item:hover .product-exclusivity {
  width: 209px;
  background-color: black; }
  .product-item:hover .product-exclusivity span {
    margin-left: 0; }

.alert {
  margin-bottom: 15px; }

.price-loader,
.popular-loader,
.newest-loader,
.product-loader {
  height: 45px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center; }
  .price-loader i,
  .popular-loader i,
  .newest-loader i,
  .product-loader i {
    color: #aaaaaa;
    line-height: 45px; }
  .price-loader span,
  .popular-loader span,
  .newest-loader span,
  .product-loader span {
    color: #aaaaaa;
    line-height: 45px; }

.padding-15 {
  padding-left: 15px;
  padding-right: 15px; }

.container .iframe {
  width: 100%;
  height: 600px; }

#fancybox-wrap, #fancybox-wrap * {
  -safari-box-sizing: content-box !important;
  box-sizing: content-box !important; }

#fancybox-wrap {
  position: absolute;
  top: 200px !important; }

.empty-basket {
  color: #ACACAC;
  text-decoration: underline; }
  .empty-basket:hover, .empty-basket:focus {
    color: #ACACAC; }

.form-group-inline {
  margin-bottom: 0; }

table tbody tr.yoyo-row td {
  border-top: 0;
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0; }

.table a .media-object {
  padding-right: 10px; }

.nowrap {
  white-space: nowrap; }

@media (max-width: 767px) {
  .col-md-2 .navbar-default {
    min-height: 0; }
    .col-md-2 .navbar-default .navbar-collapse.collapsing,
    .col-md-2 .navbar-default .navbar-collapse.in {
      min-height: 0; }
  nav.search .nav li > a {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143; }
  .cart table tr td .btn-xs {
    margin-bottom: 10px;
    font-size: 16px; }
  .cart table tr td .pull-left {
    float: none !important; }
  .cart table tr td a .media-object {
    padding-right: 0; }
  div.points {
    left: 50%;
    right: auto !important;
    width: 200px;
    transform: translateX(-50%);
    margin: 0 !important; }
    div.points .points-label {
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143; }
    div.points .points-value {
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      min-width: 69px; } }

@media (max-width: 420px) {
  .cart table tr td img {
    width: 70px; } }

.changeText {
  color: inherit;
  font-weight: 600; }

.changeText:hover,
.changeText:active {
  color: inherit;
  cursor: pointer;
  text-decoration: none; }

.estimatedCost {
  display: none; }

.width-110 {
  width: 110px; }

.filters-desktop {
  display: block; }

.container-category.container-filters,
.container-vendor.container-filters {
  padding-top: 0;
  padding-bottom: 0; }

.container-category .row-filter,
.container-vendor .row-filter {
  padding-bottom: 40px; }
  .container-category .row-filter .filters-mobile,
  .container-vendor .row-filter .filters-mobile {
    padding: 0;
    border-bottom: 1px solid #898989; }
    .container-category .row-filter .filters-mobile .form-filter.row,
    .container-vendor .row-filter .filters-mobile .form-filter.row {
      margin: 0; }
    .container-category .row-filter .filters-mobile .form-group,
    .container-vendor .row-filter .filters-mobile .form-group {
      margin: 0; }
      .container-category .row-filter .filters-mobile .form-group .filter-wrap,
      .container-vendor .row-filter .filters-mobile .form-group .filter-wrap {
        padding: 0; }
      .container-category .row-filter .filters-mobile .form-group .filter-group-header,
      .container-vendor .row-filter .filters-mobile .form-group .filter-group-header {
        font-size: 18px;
        line-height: 20px;
        font-weight: 300;
        text-transform: uppercase;
        color: #000000;
        text-align: center;
        padding: 20px 0 10px 0;
        margin: 0 0 10px 0;
        border-bottom: 0; }
        .container-category .row-filter .filters-mobile .form-group .filter-group-header .filter-group-toggle,
        .container-vendor .row-filter .filters-mobile .form-group .filter-group-header .filter-group-toggle {
          display: inline-block;
          position: relative;
          top: unset;
          right: unset;
          background-image: none;
          width: 20px;
          height: 20px;
          padding: 0;
          margin: 0;
          line-height: 20px;
          text-align: center; }
      .container-category .row-filter .filters-mobile .form-group ul li a,
      .container-vendor .row-filter .filters-mobile .form-group ul li a {
        font-size: 16px;
        color: #52565A;
        text-align: center; }
      .container-category .row-filter .filters-mobile .form-group ul li .checkbox .control-label,
      .container-vendor .row-filter .filters-mobile .form-group ul li .checkbox .control-label {
        font-size: 16px;
        color: #52565A;
        text-align: center; }

.filters-mobile {
  display: none; }

.container-help {
  overflow-x: hidden;
  padding: 20px;
  background-color: #F5F5F5;
  background-image: url("/themes/standard/images/bg_footer_new.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .container-help.alternative {
    background-image: url("/themes/standard/images/bg-kundeservice-milestone.png"); }
  .container-help .flex-wrap {
    display: -ms-flexbox;
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px; }
    .container-help .flex-wrap .flex {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -ms-flex-align: start;
      align-items: flex-start;
      padding: 20px; }
      .container-help .flex-wrap .flex:first-of-type {
        -ms-flex-pack: end;
        justify-content: flex-end; }
        .container-help .flex-wrap .flex:first-of-type.alternative {
          -ms-flex-pack: start;
          justify-content: flex-start; }
      .container-help .flex-wrap .flex:last-of-type {
        -ms-flex-pack: start;
        justify-content: flex-start; }
        .container-help .flex-wrap .flex:last-of-type.alternative {
          padding-top: 65px; }
  .container-help .contact-text {
    display: block;
    max-width: 500px;
    width: 100%;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 40px; }
    .container-help .contact-text h2 {
      color: #000000;
      font-weight: 300;
      font-size: 24px;
      margin: 0;
      text-transform: uppercase; }
    .container-help .contact-text h3 {
      color: #585758;
      font-weight: 300;
      font-size: 18px;
      margin: 0 0 40px 0; }
    .container-help .contact-text p {
      color: #585758;
      font-weight: 400;
      font-size: 13px;
      margin: 20px 0 20px 0; }
    .container-help .contact-text p ~ p {
      margin-top: 0; }
    .container-help .contact-text.alternative {
      background-color: transparent;
      max-width: 700px; }
      .container-help .contact-text.alternative h2 {
        text-transform: none !important;
        font-weight: 900 !important;
        font-size: 30px;
        margin: 0 0 20px 0; }
      .container-help .contact-text.alternative h3 {
        margin: 0 0 20px 0 !important;
        font-size: 22px !important;
        font-weight: 500 !important; }
      .container-help .contact-text.alternative p {
        font-weight: 500 !important;
        font-size: 16px; }
      .container-help .contact-text.alternative p, .container-help .contact-text.alternative h2, .container-help .contact-text.alternative h3 {
        color: #fff !important; }
  .container-help .contact-form {
    display: block;
    max-width: 300px;
    width: 100%;
    margin: 0; }
    .container-help .contact-form form .form-control {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      border: 1px solid #ECECEC;
      border-radius: 0;
      padding: 10px 20px;
      height: auto;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.5);
      color: #000000; }
      .container-help .contact-form form .form-control:-ms-input-placeholder {
        font-weight: 400;
        color: #585758; }
      .container-help .contact-form form .form-control::placeholder {
        font-weight: 400;
        color: #585758; }
      .container-help .contact-form form .form-control.alternative {
        background-color: #fff !important; }
    .container-help .contact-form form textarea {
      resize: none; }
    .container-help .contact-form form .contact-button {
      min-width: 140px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      border: 1px solid #ECECEC;
      border-radius: 0;
      padding: 10px 20px;
      height: auto;
      box-shadow: none; }
      .container-help .contact-form form .contact-button.alternative {
        color: white !important;
        background-color: transparent !important; }
  .container-help.container-help-small {
    background-image: none;
    background-color: #6DA9B3;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px; }
    .container-help.container-help-small .flex-wrap {
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 0; }
      .container-help.container-help-small .flex-wrap .flex {
        padding: 10px 10px 0 10px; }
        .container-help.container-help-small .flex-wrap .flex:first-of-type {
          -ms-flex-pack: center;
          justify-content: center; }
        .container-help.container-help-small .flex-wrap .flex:last-of-type {
          -ms-flex-pack: center;
          justify-content: center; }
    .container-help.container-help-small .contact-text {
      padding: 0;
      text-align: center;
      border: 0;
      background-color: transparent; }
      .container-help.container-help-small .contact-text h2,
      .container-help.container-help-small .contact-text p {
        color: #FFFFFF;
        margin-bottom: 0; }
    .container-help.container-help-small .contact-form form .form-control {
      background-color: #FFFFFF;
      border: 1px solid #FFFFFF;
      font-size: 12px;
      padding: 10px; }
    .container-help.container-help-small .contact-form form .contact-button {
      width: 100%;
      color: #FFFFFF;
      background-color: #A4C3C8;
      border: 1px solid #A4C3C8;
      transition: all .2s ease-in-out; }
      .container-help.container-help-small .contact-form form .contact-button:hover {
        color: #FFFFFF;
        background-color: #6EA9B3;
        border: 1px solid #A4C3C8; }

section.container-giftuniverse,
.container-giftuniverse {
  padding: 40px; }
  section.container-giftuniverse .flex-row,
  .container-giftuniverse .flex-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-direction: row;
    flex-direction: row; }
    section.container-giftuniverse .flex-row .flex-col,
    .container-giftuniverse .flex-row .flex-col {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-align: center;
      align-items: center; }
  section.container-giftuniverse .giftuniverse-image,
  .container-giftuniverse .giftuniverse-image {
    position: relative;
    width: 100%;
    height: 300px;
    background-image: url("/themes/standard/images/bg-gaveuniverset.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }
  section.container-giftuniverse .giftuniverse-text h2,
  .container-giftuniverse .giftuniverse-text h2 {
    text-align: center;
    color: #000000;
    font-weight: 300;
    font-size: 24px;
    margin: 0;
    text-transform: uppercase; }
    section.container-giftuniverse .giftuniverse-text h2 a,
    .container-giftuniverse .giftuniverse-text h2 a {
      text-decoration: none;
      color: inherit; }
  section.container-giftuniverse .giftuniverse-text p,
  .container-giftuniverse .giftuniverse-text p {
    text-align: center;
    color: #585758;
    font-weight: 400;
    font-size: 13px;
    margin: 20px 0 20px 0; }
  section.container-giftuniverse .giftuniverse-text .giftuniverse-brands,
  .container-giftuniverse .giftuniverse-text .giftuniverse-brands {
    width: 100%;
    height: 40px;
    background-image: url("/themes/standard/images/bg-gaveuniverset-brands.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 30px 0 30px 0; }
  section.container-giftuniverse .giftuniverse-text .giftuniverse-button,
  .container-giftuniverse .giftuniverse-text .giftuniverse-button {
    text-align: center; }
    section.container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button,
    .container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button {
      display: inline-block;
      background-color: #A4C3C8;
      color: #FFFFFF;
      box-shadow: none;
      border-radius: 0;
      font-weight: normal;
      min-width: 225px;
      padding: 15px;
      margin-left: 20px;
      margin-right: 20px;
      transition: all .2s ease-in-out; }
      section.container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button:focus, section.container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button:hover, section.container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button:active,
      .container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button:focus,
      .container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button:hover,
      .container-giftuniverse .giftuniverse-text .giftuniverse-button .gaveuniverset-button:active {
        text-decoration: none;
        background-color: #6EA9B3;
        color: #FFFFFF; }

.container-newsletter {
  background-color: #EBEBEB; }
  .container-newsletter > .container {
    background-image: url("/themes/standard/images/bg-logo.png");
    background-size: 250px 250px;
    background-repeat: no-repeat;
    background-position: right 20% bottom -40px; }

.footer {
  background-color: transparent; }
  .footer .container-menu {
    padding-top: 60px; }
    .footer .container-menu.alternative {
      width: 100%;
      overflow-x: hidden;
      background: linear-gradient(to left, #035d88, #023852); }
      .footer .container-menu.alternative a, .footer .container-menu.alternative p {
        color: #ffffff; }
      .footer .container-menu.alternative h2 {
        font-weight: 600;
        color: #008ac6; }
  .footer h2 {
    color: #000000;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    margin: 0 auto 0 auto;
    text-transform: uppercase; }
  .footer p {
    color: #585758;
    font-weight: 400;
    font-size: 13px;
    margin: 15px auto 15px auto;
    max-width: 550px; }
    .footer p a {
      text-decoration: none;
      color: inherit; }
      .footer p a:hover {
        text-decoration: underline;
        color: #A5C6CB; }
    .footer p em {
      color: #A4C3C8;
      font-weight: normal;
      font-style: normal;
      text-decoration: none; }
  .footer p ~ p {
    margin-top: 0; }
  .footer .row-newsletter {
    margin-top: 55px;
    margin-bottom: 55px; }
    .footer .row-newsletter h2 {
      text-align: center;
      color: #000000;
      font-weight: 300;
      font-size: 24px;
      line-height: 24px;
      margin: 0 auto 0 auto;
      text-transform: uppercase; }
    .footer .row-newsletter p {
      text-align: center;
      color: #585758;
      font-weight: 400;
      font-size: 13px;
      margin: 20px auto 20px auto;
      max-width: 550px; }
  .footer .row-menu {
    position: relative; }
  .footer .footer-nav {
    display: inline-block;
    margin: 0;
    text-align: left; }
    .footer .footer-nav h2 {
      font-size: 16px;
      line-height: 16px;
      max-width: 260px; }
    .footer .footer-nav p {
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      max-width: 260px; }
  .footer .middle-border {
    position: absolute;
    top: 0;
    width: 1px;
    height: 80%;
    background-color: #D3D3D3; }
  .footer .middle-border:nth-child(1) {
    left: calc(25% - 1px); }
  .footer .middle-border:nth-child(2) {
    left: calc(50% - 1px); }
  .footer .middle-border:nth-child(3) {
    left: calc(75% - 1px); }
  .footer .row-icons {
    position: relative;
    text-align: left;
    margin: 40px 0 0 0; }
    .footer .row-icons.alternative {
      margin: unset;
      float: right; }
      @media (max-width: 992px) {
        .footer .row-icons.alternative {
          float: none; } }
    .footer .row-icons .link-facebook,
    .footer .row-icons .link-instagram,
    .footer .row-icons .link-linkedin {
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 3px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      font-size: 0; }
    .footer .row-icons .link-facebook {
      background-image: url("/themes/standard/images/social-icon-facebook.png"); }
      .footer .row-icons .link-facebook.alternative {
        background-image: url("/themes/standard/images/milestone-facebook.png");
        background-size: cover;
        border-radius: 50px;
        width: 45px;
        height: 45px; }
    .footer .row-icons .link-instagram {
      background-image: url("/themes/standard/images/social-icon-instagram.png"); }
      .footer .row-icons .link-instagram.alternative {
        background-image: url("/themes/standard/images/milestone-instagram.png");
        background-size: cover;
        border-radius: 50px;
        width: 45px;
        height: 45px; }
    .footer .row-icons .link-linkedin {
      background-image: url("/themes/standard/images/social-icon-linkedin.png"); }
      .footer .row-icons .link-linkedin.alternative {
        background-image: url("/themes/standard/images/milestone-linkedin.png");
        background-size: cover;
        border-radius: 50px;
        width: 45px;
        height: 45px; }

.bottom-images {
  text-align: center;
  background-color: #FFFFFF;
  padding: 30px 0 30px 0; }
  .bottom-images .bottom-image-link {
    margin: 5px;
    position: relative;
    display: inline-block;
    width: auto;
    font-size: 0; }
    .bottom-images .bottom-image-link:hover {
      cursor: default; }
  .bottom-images a.bottom-image-link:hover {
    cursor: pointer; }
  .bottom-images img {
    position: relative;
    display: inline-block;
    width: auto;
    height: 33px;
    margin: 0;
    padding: 0;
    font-size: 0;
    filter: grayscale(100%);
    transition: all .2s ease-in-out;
    opacity: 0.5; }
    .bottom-images img:hover {
      filter: grayscale(0%);
      opacity: 1; }

.container-testimonial {
  position: relative;
  background-color: #E4EEEF;
  padding-top: 45px;
  padding-bottom: 45px;
  background-size: 173px 137px;
  background-repeat: no-repeat;
  background-position: calc(50% + 173px) 50%;
  background-image: url("/themes/standard/images/icon-quote.png");
  min-height: 200px; }
  .container-testimonial h2 {
    text-align: center;
    color: #000000;
    font-weight: 300;
    font-size: 24px;
    margin: 0;
    text-transform: uppercase; }
  .container-testimonial p {
    text-align: center;
    color: #585758;
    font-weight: 400;
    font-size: 16px;
    margin: 20px auto 20px auto;
    max-width: 450px; }
  .container-testimonial .carousel-fade .carousel-inner .item {
    transition-property: opacity; }
  .container-testimonial .carousel-fade .carousel-inner .item,
  .container-testimonial .carousel-fade .carousel-inner .active.left,
  .container-testimonial .carousel-fade .carousel-inner .active.right {
    opacity: 0; }
  .container-testimonial .carousel-fade .carousel-inner .active,
  .container-testimonial .carousel-fade .carousel-inner .next.left,
  .container-testimonial .carousel-fade .carousel-inner .prev.right {
    opacity: 1; }
  .container-testimonial .carousel-fade .carousel-inner .next,
  .container-testimonial .carousel-fade .carousel-inner .prev,
  .container-testimonial .carousel-fade .carousel-inner .active.left,
  .container-testimonial .carousel-fade .carousel-inner .active.right {
    left: 0;
    transform: translate3d(0, 0, 0); }

.carousel-inner video {
  position: relative;
  width: 100%;
  height: auto; }

.header-fixed-wrap {
  position: fixed;
  top: -999px;
  left: 0;
  right: 0;
  z-index: 20;
  transition: top .2s ease-in-out;
  box-shadow: 0 1px 2px 0 rgba(88, 88, 88, 0.2);
  border: 0;
  background-color: #FFFFFF; }
  .header-fixed-wrap .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1890px;
    width: 100%; }
    .header-fixed-wrap .container .header-bottom-menu {
      margin-bottom: 0;
      padding: 10px 15px 10px 15px;
      margin-left: -15px;
      margin-right: -15px; }
      .header-fixed-wrap .container .header-bottom-menu .header-link a {
        font-size: 12px;
        padding: 10px 15px 10px 15px; }
      .header-fixed-wrap .container .header-bottom-menu .header-link.alternative {
        margin: 0 1%; }
        .header-fixed-wrap .container .header-bottom-menu .header-link.alternative a {
          font-weight: 600 !important;
          color: #000000 !important;
          text-transform: capitalize !important;
          font-size: 16px; }
          @media (max-width: 1200px) {
            .header-fixed-wrap .container .header-bottom-menu .header-link.alternative a {
              font-size: 12px; } }
      .header-fixed-wrap .container .header-bottom-menu .dropdown-categories-brands .dropdown-toggle,
      .header-fixed-wrap .container .header-bottom-menu .dropdown-pages .dropdown-toggle,
      .header-fixed-wrap .container .header-bottom-menu .dropdown-user .dropdown-toggle,
      .header-fixed-wrap .container .header-bottom-menu .dropdown-currency .dropdown-toggle,
      .header-fixed-wrap .container .header-bottom-menu .dropdown-languages .dropdown-toggle {
        font-size: 12px;
        padding: 10px 15px 10px 15px; }
      .header-fixed-wrap .container .header-bottom-menu .dropdown-categories-brands.alternative {
        margin: 0 1%; }
        .header-fixed-wrap .container .header-bottom-menu .dropdown-categories-brands.alternative a {
          font-weight: 600 !important;
          color: #000000 !important;
          text-transform: capitalize !important;
          font-size: 16px; }
          @media (max-width: 1200px) {
            .header-fixed-wrap .container .header-bottom-menu .dropdown-categories-brands.alternative a {
              font-size: 12px; } }
      .header-fixed-wrap .container .header-bottom-menu .flex-menu .header-side-logo {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-preferred-size: 40px;
        flex-basis: 40px;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: start;
        justify-content: flex-start; }
        .header-fixed-wrap .container .header-bottom-menu .flex-menu .header-side-logo .header-logo-small {
          display: block;
          width: 40px;
          height: 40px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          color: transparent;
          text-decoration: none; }
          .header-fixed-wrap .container .header-bottom-menu .flex-menu .header-side-logo .header-logo-small:hover {
            cursor: pointer;
            color: transparent;
            text-decoration: none; }
    .header-fixed-wrap .container .header-side-left {
      min-height: 40px; }
    .header-fixed-wrap .container .header-side-right {
      -ms-flex-preferred-size: 355px;
      flex-basis: 355px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      min-height: 40px; }
    .header-fixed-wrap .container div.search {
      width: 235px;
      height: 40px;
      margin: 0;
      padding: 0 15px 0 0; }
      .header-fixed-wrap .container div.search input {
        width: 220px;
        height: 40px;
        padding: 10px 50px 10px 15px;
        background-size: 20px 20px;
        background-position: right 15px center; }
    .header-fixed-wrap .container .dropdown-cart {
      width: 40px;
      height: 40px; }
      .header-fixed-wrap .container .dropdown-cart .dropdown-toggle {
        width: 40px;
        height: 40px; }
      .header-fixed-wrap .container .dropdown-cart .cart-icon {
        width: 40px;
        height: 40px;
        border-width: 2px;
        background-size: 25px 22px; }
        .header-fixed-wrap .container .dropdown-cart .cart-icon .cart-item-count {
          width: 20px;
          height: 20px;
          font-size: 12px;
          line-height: 12px;
          padding: 4px 0 4px 0;
          bottom: -6px;
          right: -6px; }
      .header-fixed-wrap .container .dropdown-cart .dropdown-menu {
        top: 50px; }

.section-wrap {
  position: relative;
  background-color: #FFFFFF; }

.row .brands-subheader,
.row .other-subheader {
  color: #9E9E9E;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  margin: 25px 0 10px 0; }

.row .brands-header,
.row .other-header {
  color: #000000;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  margin: 0 0 60px 0; }

.container-brands .row-brands {
  padding-left: 40px;
  padding-right: 40px; }

.product-content-wrap {
  padding-left: 45px; }
  .product-content-wrap h1 {
    text-transform: uppercase; }

.row-product-specifications {
  padding-bottom: 60px; }
  .row-product-specifications .specifications-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 60px 0; }
  .row-product-specifications .specifications-text {
    color: #585758;
    font-weight: 400;
    font-size: 13px;
    max-width: 1030px;
    padding: 0 15px 0 15px;
    margin: 0 auto; }

.row-product-sustainability {
  padding-bottom: 60px; }
  .row-product-sustainability .sustainability-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 60px 0;
    text-transform: uppercase; }
  .row-product-sustainability .sustainability-text {
    color: #585758;
    font-weight: 400;
    font-size: 13px;
    max-width: 1030px;
    padding: 0 15px 0 15px;
    margin: 0 auto; }

.product-selling-points {
  margin-top: 40px; }
  .product-selling-points p {
    margin: 0;
    font-weight: 400;
    color: #000000; }
    .product-selling-points p b {
      font-weight: 600; }

.modal .modal-dialog .modal-content {
  border: 0;
  border-radius: 20px; }
  .modal .modal-dialog .modal-content .btn-default {
    background-color: #A4C3C8;
    box-shadow: none;
    border-radius: 0;
    font-weight: normal;
    min-width: 150px;
    padding: 15px;
    margin-left: 20px;
    margin-right: 20px;
    transition: all .2s ease-in-out; }
    .modal .modal-dialog .modal-content .btn-default:focus, .modal .modal-dialog .modal-content .btn-default:hover, .modal .modal-dialog .modal-content .btn-default:active {
      text-decoration: none;
      background-color: #6EA9B3;
      color: #FFFFFF; }
  .modal .modal-dialog .modal-content .co-title {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
    padding: 15px 0 0 0; }
  .modal .modal-dialog .modal-content .co-subtitle {
    font-size: 20px;
    text-align: center;
    text-transform: lowercase;
    line-height: 1; }
  .modal .modal-dialog .modal-content .co-price {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    padding: 15px 0 15px 0; }
  .modal .modal-dialog .modal-content .co-totals {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    padding: 15px 0 15px 0; }
  .modal .modal-dialog .modal-content .co-shipping {
    font-size: 14px;
    text-align: center;
    padding: 7px 0 7px 0; }
    .modal .modal-dialog .modal-content .co-shipping span {
      font-weight: 700; }
  .modal .modal-dialog .modal-content .co-buttons {
    text-align: center;
    padding: 0; }
    .modal .modal-dialog .modal-content .co-buttons .btn {
      margin-bottom: 15px; }
  .modal .modal-dialog .modal-content .co-image-wrap {
    padding: 15px 0 15px 0; }
    .modal .modal-dialog .modal-content .co-image-wrap .co-image {
      position: relative;
      margin: 0 auto 0 auto;
      padding-bottom: 40%;
      width: 40%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
  .modal .modal-dialog .modal-content .co-see-also {
    font-size: 20px;
    text-align: center;
    line-height: 1;
    padding: 15px 0 15px 0; }
  .modal .modal-dialog .modal-content .co-row-products {
    padding: 0 0 7px 0; }

.search-dropdown-toggle {
  position: absolute;
  z-index: -9;
  top: 0;
  left: 0;
  height: 1px;
  width: 1px;
  opacity: 0; }

.search-dropdown-menu {
  position: absolute;
  top: 100%;
  left: unset;
  right: 0;
  z-index: 1000;
  margin: 0;
  width: 380px;
  padding: 20px;
  border-radius: 0 0 20px 20px;
  border-top: 0;
  border-left: 1px solid #EEEEEE;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE; }
  .search-dropdown-menu a {
    font-size: 13px;
    line-height: 1.4;
    text-decoration: none;
    color: inherit; }
  .search-dropdown-menu .sd-product-wrap {
    border: 1px solid #898989;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 152px;
    font-size: 13px;
    line-height: 1.4; }
    .search-dropdown-menu .sd-product-wrap .flex-center {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center; }
    .search-dropdown-menu .sd-product-wrap .sd-flex {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 15px 7px 15px 7px;
      -ms-flex-direction: column;
      flex-direction: column; }
      .search-dropdown-menu .sd-product-wrap .sd-flex p {
        margin: 0; }
      .search-dropdown-menu .sd-product-wrap .sd-flex a p:last-of-type {
        margin-top: 20px; }
    .search-dropdown-menu .sd-product-wrap .sd-flex-1 {
      -ms-flex: 0 0 120px;
      flex: 0 0 120px; }
    .search-dropdown-menu .sd-product-wrap .sd-flex-2 {
      -ms-flex: 0 1 100%;
      flex: 0 1 100%; }
    .search-dropdown-menu .sd-product-wrap .sd-product-image {
      display: block;
      width: 106px;
      height: 106px;
      padding: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
  .search-dropdown-menu .sd-product-wrap ~ .sd-product-wrap {
    margin-top: 15px; }
  .search-dropdown-menu .sd-content {
    position: relative;
    overflow: auto;
    max-height: calc(100vh - 206px);
    min-height: 152px; }
  .search-dropdown-menu .sd-footer {
    display: none;
    position: relative;
    padding: 15px 0 0 0;
    text-align: right; }

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.info-block {
  color: #000000;
  font-size: 13px;
  text-align: right;
  margin-top: 60px;
  font-weight: 300;
  margin-bottom: 30px; }
  .info-block > div {
    padding: 4px 0 4px 0; }
  .info-block .info-block-flex-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-top: 1px solid #DDDDDD; }
    .info-block .info-block-flex-wrap .info-block-flex {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: start;
      justify-content: flex-start;
      height: 40px; }
      .info-block .info-block-flex-wrap .info-block-flex:first-of-type {
        text-transform: uppercase; }
      .info-block .info-block-flex-wrap .info-block-flex:last-of-type {
        -ms-flex-pack: end;
        justify-content: flex-end; }
    .info-block .info-block-flex-wrap.info-block-price .info-block-flex:nth-of-type(2) {
      -ms-flex-preferred-size: 70%;
      flex-basis: 70%; }
  .info-block .info-block-price {
    color: #000000; }
    .info-block .info-block-price .info-block-flex:last-of-type {
      font-size: 26px; }

.options-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 16px;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 4px 0 4px 0; }
  .options-block .form-control {
    border-radius: 0;
    height: 45px;
    line-height: 23px;
    padding: 10px 11px 10px 11px; }
  .options-block .options-block-txt {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 200px;
    flex-basis: 200px;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .options-block .options-block-val {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0 0 0 10px; }

.order-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 16px;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 4px 0 4px 0;
  margin-top: 30px; }
  .order-block .form-control {
    border-radius: 0;
    height: 45px;
    line-height: 23px;
    padding: 10px 11px 10px 11px; }
  .order-block .btn {
    width: 100%;
    max-width: 250px;
    background-color: #A4C3C8;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    font-weight: normal;
    min-width: 150px;
    padding: 11px 11px 11px 11px;
    line-height: 23px;
    transition: all .2s ease-in-out; }
    .order-block .btn:focus, .order-block .btn:hover, .order-block .btn:active {
      text-decoration: none;
      background-color: #6EA9B3;
      color: #FFFFFF; }
  .order-block .order-block-txt {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: start;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 70px;
    flex-basis: 70px; }
  .order-block .order-block-qty {
    -ms-flex-preferred-size: 110px;
    flex-basis: 110px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0 10px 0 10px; }
    .order-block .order-block-qty input {
      text-align: center; }
  .order-block .order-block-btn {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%; }
    .order-block .order-block-btn .btn {
      max-width: none; }
  .order-block > .order-block-qty {
    padding-left: 0; }

.alert-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 16px;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0; }
  .alert-block .alert {
    border-radius: 0;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin: 4px 0 4px 0; }

.product-content h2 {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 40px 0 40px 0;
  color: #000000; }

.cart-table-flex {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  min-width: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #464646;
  font-size: 21px;
  line-height: 21px;
  font-weight: 400; }
  .cart-table-flex .cart-header-flex,
  .cart-table-flex .cart-body-flex,
  .cart-table-flex .cart-footer-flex {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    min-width: 0; }
    .cart-table-flex .cart-header-flex .cart-row-flex,
    .cart-table-flex .cart-body-flex .cart-row-flex,
    .cart-table-flex .cart-footer-flex .cart-row-flex {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      width: 100%;
      min-width: 0; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex,
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex,
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 10px 8px 10px 8px; }
        .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:first-child,
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:first-child,
        .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:first-child {
          padding-left: 20px; }
        .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:last-child,
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:last-child,
        .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:last-child {
          padding-right: 20px; }
  .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex {
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    color: #464646;
    font-weight: 600; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex: 0 0 160px;
      flex: 0 0 160px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex: 0 0 225px;
      flex: 0 0 225px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(4) {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex: 0 0 160px;
      flex: 0 0 160px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(5) {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex: 0 0 100px;
      flex: 0 0 100px; }
  .cart-table-flex .cart-body-flex .cart-row-flex {
    background-color: #EBEBEB;
    -ms-flex-align: center;
    align-items: center; }
    .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex {
      -ms-flex-align: center;
      align-items: center; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 160px;
        flex: 0 0 160px; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex: 0 0 225px;
        flex: 0 0 225px; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(4) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 160px;
        flex: 0 0 160px; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(5) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 100px;
        flex: 0 0 100px; }
  .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex {
    -ms-flex-align: center;
    align-items: center;
    min-height: 46px; }
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%; }
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex: 0 0 160px;
      flex: 0 0 160px; }
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex: 0 0 100px;
      flex: 0 0 100px; }
  .cart-table-flex a,
  .cart-table-flex a:hover {
    color: #464646; }

.show-mobile-flex {
  display: none; }

.flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

#shop-carousel .carousel-inner .item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 0; }

.g-recaptcha-wrap {
  position: relative;
  max-width: 25px;
  max-height: 65px; }
  .g-recaptcha-wrap .g-recaptcha {
    transform: scale(0.82236842);
    transform-origin: left top; }

#showNumber {
  font-size: 12px;
  height: 25px;
  padding-top: 0; }

.category-flex-row,
.vendor-flex-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 0;
  min-width: 0;
  margin-right: -15px;
  margin-left: -15px; }
  .category-flex-row .category-flex-sidebar,
  .category-flex-row .vendor-flex-sidebar,
  .vendor-flex-row .category-flex-sidebar,
  .vendor-flex-row .vendor-flex-sidebar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px; }
  .category-flex-row .category-flex-content,
  .category-flex-row .vendor-flex-content,
  .vendor-flex-row .category-flex-content,
  .vendor-flex-row .vendor-flex-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px; }

.container-brands .row-brands-master .master-brand,
section.container-brands .row-brands-master .master-brand {
  position: relative;
  display: block;
  border: 1px solid #ECECEC;
  padding: 15px;
  margin-top: 30px; }
  .container-brands .row-brands-master .master-brand .master-brand-image,
  section.container-brands .row-brands-master .master-brand .master-brand-image {
    position: relative;
    display: block;
    padding-bottom: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
  .container-brands .row-brands-master .master-brand .master-brand-name,
  section.container-brands .row-brands-master .master-brand .master-brand-name {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    color: #000000;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 300;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.container-brands .row-brands,
section.container-brands .row-brands {
  margin-top: 60px;
  margin-bottom: 60px; }
  .container-brands .row-brands .brands-subheader,
  section.container-brands .row-brands .brands-subheader {
    color: #9E9E9E;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 10px 0; }
  .container-brands .row-brands .brands-header,
  section.container-brands .row-brands .brands-header {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    margin: 0 0 0 0; }

.container-brands .row-brands-list,
section.container-brands .row-brands-list {
  margin-bottom: 0; }
  .container-brands .row-brands-list h3,
  section.container-brands .row-brands-list h3 {
    color: #000000;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    text-align: left;
    margin: 0 0 10px 0;
    text-transform: uppercase; }
  .container-brands .row-brands-list p,
  section.container-brands .row-brands-list p {
    margin: 0;
    padding: 7px 0 7px 0; }
  .container-brands .row-brands-list a,
  section.container-brands .row-brands-list a {
    color: #7D7D7D;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

/** jCarousel **/
.jcarousel-wrapper {
  margin: 20px auto;
  position: relative;
  border: 10px solid #fff;
  border-radius: 5px;
  box-shadow: 0 0 2px #999; }

.jcarousel {
  display: block;
  position: relative;
  overflow: hidden;
  width: auto;
  margin-left: -5px;
  margin-right: -5px; }

.jcarousel ul {
  width: 10000em;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0; }

.jcarousel li {
  position: relative;
  width: 200px;
  float: left;
  border: 1px solid #fff;
  box-sizing: border-box; }

.jcarousel .jcarousel-image-wrap {
  display: block;
  position: relative;
  width: auto;
  padding: 0 20px 0 20px;
  box-sizing: border-box; }

.jcarousel .jcarousel-image {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-bottom: 62px;
  height: auto !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  border: 1px solid #ECECEC; }

.jcarousel a.jcarousel-image {
  text-decoration: none;
  border: 0; }

.jcarousel a.jcarousel-image:hover {
  cursor: pointer; }

.jcarousel .jcarousel-image-border {
  position: relative;
  border: 1px solid #ECECEC;
  padding: 5px; }

@media (max-width: 1199px) {
  .header .container .header-top {
    -ms-flex-direction: row;
    flex-direction: row;
    min-height: 40px;
    height: auto;
    max-height: none; }
    .header .container .header-top .header-side-left {
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      min-height: 40px; }
    .header .container .header-top .header-side-middle {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      min-height: 40px; }
    .header .container .header-top .header-side-right {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      min-height: 40px; }
  .header .container .header-side-left {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-height: 40px; }
  .header .container .header-side-right {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-height: 40px; }
  .header-fixed-wrap .container .header-side-left {
    min-height: 40px; }
  .header-fixed-wrap .container .header-side-right {
    -ms-flex-preferred-size: 370px;
    flex-basis: 370px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    min-height: 40px; }
  .cart-product-image {
    width: 100px;
    height: 130px;
    min-width: 118px;
    margin-right: 7px; }
  .container-promos .promo-header,
  section.container-promos .promo-header {
    font-size: 28px; }
  .container-promos .promo-content,
  section.container-promos .promo-content {
    margin-left: 0;
    margin-right: 0; }
    .container-promos .promo-content p,
    section.container-promos .promo-content p {
      margin: 0; }
  .container-promos .row-promos .category-wrap .category-title h3,
  section.container-promos .row-promos .category-wrap .category-title h3 {
    font-size: 16px; } }

@media (max-width: 1023px) {
  .header .container .header-top .header-side-left {
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-height: 40px; }
  .header .container .header-top .header-side-middle {
    display: none; }
  .header .container .header-top .header-side-right {
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    min-height: 40px; }
  .header .dropdown-categories-brands .dropdown-menu {
    padding: 15px; } }

@media (max-width: 991px) {
  .btn.btn-action {
    padding: 12px; }
  .container-carousel .carousel .carousel-indicators {
    display: none; }
  .carousel .slide-widget .slide-widget-top-text {
    font-size: 13px; }
  .carousel .slide-widget .slide-widget-text {
    font-size: 30px;
    margin: 0 0 15px 0; }
  .carousel .slide-widget .slide-widget-button {
    margin: 0;
    padding: 12px; }
  .header .container .dropdown-categories-brands .dropdown-toggle,
  .header .container .dropdown-pages .dropdown-toggle {
    font-size: 12px;
    text-align: center;
    width: 100%;
    max-width: none; }
  .header .container .header-link a {
    font-size: 12px;
    text-align: center;
    width: 100%;
    max-width: none; }
  .header .container .dropdown-pages .dropdown-menu {
    text-align: center; }
  .container-cart .page-header h2 {
    font-size: 20px; }
  .container-cart .page-header p {
    font-size: 15px;
    margin-right: 150px; }
  .cart-product-image {
    width: 70px;
    height: 70px;
    min-width: 70px;
    margin-right: 7px; }
  .container-cart .cart-proceed-row .form-group {
    float: none; }
  .container-cart .cart-proceed-row .form-group input[type="text"] {
    margin: 0;
    max-width: none; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap {
    background: none; }
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
      width: 100%;
      float: none;
      min-height: 0;
      padding: 0 0 15px 0; }
      .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-title {
        height: 30px; }
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right {
      width: 100%;
      float: none;
      min-height: 0;
      padding: 0; }
      .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title {
        height: 30px; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
    -ms-flex: 0 0 200px;
    flex: 0 0 200px; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-title,
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title {
    font-size: 12px; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories,
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands {
    font-size: 12px; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right-1 {
    padding-top: 15px; }
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right-1 .list-brands a:last-child {
      padding: 7px 0 7px 0; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right-2 .list-title {
    display: none; }
  .header {
    border-bottom: 1px solid #898989; }
  .container-category.container-filters,
  .container-vendor.container-filters {
    padding-right: 20px;
    padding-left: 20px; }
  .container-category .category-main-image,
  .container-vendor .category-main-image {
    display: none; }
  .container-category .category-breadcrumb,
  .container-category .vendor-breadcrumb,
  .container-vendor .category-breadcrumb,
  .container-vendor .vendor-breadcrumb {
    display: none; }
  .container-category .row-header,
  .container-vendor .row-header {
    padding-bottom: 20px;
    background-color: transparent; }
  .container-category .category-main-title,
  .container-category .vendor-main-title,
  .container-vendor .category-main-title,
  .container-vendor .vendor-main-title {
    margin: 30px 0 20px 0; }
  .container-category .row-filter,
  .container-vendor .row-filter {
    margin-right: -20px;
    margin-left: -20px;
    padding-bottom: 0; }
    .container-category .row-filter .filters-mobile .form-group .filter-wrap.filter-active,
    .container-vendor .row-filter .filters-mobile .form-group .filter-wrap.filter-active {
      padding-bottom: 15px; }
    .container-category .row-filter .filter-header,
    .container-vendor .row-filter .filter-header {
      display: none; }
    .container-category .row-filter .form-group ul,
    .container-vendor .row-filter .form-group ul {
      padding: 0; }
  .container-category .category-main-description .description-desktop,
  .container-category .vendor-main-description .description-desktop,
  .container-vendor .category-main-description .description-desktop,
  .container-vendor .vendor-main-description .description-desktop {
    display: none; }
  .container-category .category-main-description .description-mobile,
  .container-category .vendor-main-description .description-mobile,
  .container-vendor .category-main-description .description-mobile,
  .container-vendor .vendor-main-description .description-mobile {
    display: block; }
  .filters-desktop {
    display: none; }
  .filters-mobile {
    display: block; }
  .container-help .contact-background {
    background-image: none; }
  .footer .middle-border {
    display: none; }
  .footer .footer-nav {
    display: block; }
    .footer .footer-nav h2 {
      text-align: center; }
    .footer .footer-nav p {
      text-align: center; }
  .footer .row-icons {
    text-align: center; }
  .container-content .row-content .flex-content {
    -ms-flex-direction: column;
    flex-direction: column; }
    .container-content .row-content .flex-content .flex-image {
      padding: 0 0 73.43% 0; }
  .container-content .row-content .flex-content:nth-of-type(odd) {
    -ms-flex-direction: column;
    flex-direction: column; }
  .container-content .row-content .flex-content:nth-of-type(even) {
    -ms-flex-direction: column;
    flex-direction: column; }
  .product-content-wrap {
    padding-left: 0; }
  .container-nature,
  section.container-nature {
    position: relative; }
    .container-nature .container-nature-wrap,
    section.container-nature .container-nature-wrap {
      padding: 60px 20px 60px 20px;
      margin-left: 0; }
    .container-nature .nature-icon,
    section.container-nature .nature-icon {
      top: 40px;
      right: 20px;
      z-index: 2;
      width: 40px;
      height: 40px; }
  .cart-table-flex {
    font-size: 13px;
    line-height: 13px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex,
    .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex,
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex {
      padding: 7px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:first-child,
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:first-child,
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:first-child {
        padding-left: 7px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:last-child,
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:last-child,
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:last-child {
        padding-right: 7px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex: 0 0 95px;
      flex: 0 0 95px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex: 0 0 200px;
      flex: 0 0 200px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(4) {
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex: 0 0 110px;
      flex: 0 0 110px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(5) {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex: 0 0 90px;
      flex: 0 0 90px; }
    .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex {
      -ms-flex-align: center;
      align-items: center; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 95px;
        flex: 0 0 95px; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex: 0 0 200px;
        flex: 0 0 200px; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(4) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 110px;
        flex: 0 0 110px; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(5) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px; }
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex {
      min-height: 34px; }
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; }
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 110px;
        flex: 0 0 110px; }
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px; }
  .container-help-wrap {
    display: none; }
  .container-promos .row-promos .promo-wrap .category-image-wrap .category-image,
  section.container-promos .row-promos .promo-wrap .category-image-wrap .category-image {
    display: none; }
  .container-promos .row-promos .promo-wrap .category-image-wrap .promo-content-wrap,
  section.container-promos .row-promos .promo-wrap .category-image-wrap .promo-content-wrap {
    position: relative; }
    .container-promos .row-promos .promo-wrap .category-image-wrap .promo-content-wrap .promo-content p ~ p,
    section.container-promos .row-promos .promo-wrap .category-image-wrap .promo-content-wrap .promo-content p ~ p {
      margin-top: 10px; }
  .category-flex-row,
  .vendor-flex-row {
    -ms-flex-direction: column;
    flex-direction: column; }
    .category-flex-row .category-flex-sidebar,
    .category-flex-row .vendor-flex-sidebar,
    .vendor-flex-row .category-flex-sidebar,
    .vendor-flex-row .vendor-flex-sidebar {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
    .category-flex-row .category-flex-content,
    .category-flex-row .vendor-flex-content,
    .vendor-flex-row .category-flex-content,
    .vendor-flex-row .vendor-flex-content {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto; } }

@media (max-width: 767px) {
  .container-cart .cart-proceed-row [class*="col-"] ~ [class*="col-"] {
    padding-left: 15px; }
  .container-cart .cart-proceed-row [class*="col-"]:first-child {
    padding-right: 15px; }
  .dropdown-cart .cart-icon .cart-item-count {
    bottom: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 12px;
    padding: 4px 0; }
  .header .container .header-top .header-link a {
    min-width: 0; }
  .header .container .header-top {
    min-height: 40px; }
    .header .container .header-top .header-side-left {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -ms-flex-pack: center;
      justify-content: center; }
    .header .container .header-top .header-side-right {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      -ms-flex-pack: center;
      justify-content: center; }
  .header .header-wrap-top {
    display: none; }
  .header .container {
    padding-bottom: 0; }
    .header .container .flex-menu {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column; }
    .header .container .header-mobile-logo-text {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      font-size: 12px;
      line-height: 12px;
      margin-top: 10px; }
    .header .container .header-mobile-placeholder {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 40px;
      flex: 0 0 40px;
      position: relative;
      width: 40px;
      height: 40px;
      max-height: 40px;
      padding: 0;
      margin: 0 20px 0 0; }
    .header .container .header-mobile-logo {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -ms-flex-direction: column;
      flex-direction: column; }
    .header .container .header-mobile-burger {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 40px;
      flex: 0 0 40px;
      position: relative;
      width: 40px;
      height: 40px;
      max-height: 40px;
      padding: 0;
      margin-left: 20px; }
      .header .container .header-mobile-burger .burger-toggle {
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 40px;
        line-height: 40px;
        margin: 0;
        text-align: right; }
        .header .container .header-mobile-burger .burger-toggle:hover {
          cursor: pointer; }
    .header .container .header-mobile-account {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 40px;
      flex: 0 0 40px;
      position: relative;
      width: 40px;
      height: 40px;
      max-height: 40px;
      padding: 0;
      margin-left: 20px; }
      .header .container .header-mobile-account .dropdown-toggle {
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 36px;
        line-height: 40px;
        margin: 0;
        text-align: right; }
        .header .container .header-mobile-account .dropdown-toggle:hover {
          cursor: pointer; }
    .header .container .header-side-left {
      position: relative;
      display: block;
      height: 0;
      min-height: 0;
      max-height: none;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      float: none;
      overflow: hidden; }
      .header .container .header-side-left.collapse {
        display: none;
        height: auto; }
        .header .container .header-side-left.collapse.in {
          display: block;
          height: auto; }
      .header .container .header-side-left.collapsing {
        position: relative;
        height: 0;
        min-height: 0; }
      .header .container .header-side-left .dropdown .dropdown-menu {
        position: relative;
        top: 0; }
      .header .container .header-side-left .dropdown-categories-brands,
      .header .container .header-side-left .header-link {
        float: none;
        width: 100%; }
    .header .container .header-bottom-menu {
      -ms-flex-direction: column;
      flex-direction: column; }
  .container-brands .row-brands {
    padding-left: 0;
    padding-right: 0; }
  .container .header-top {
    height: auto;
    float: none; }
  .header.header-transparent .header-bottom-menu .header-side-left {
    background-color: #FFFFFF; }
  .header.header-transparent .container .header-link a {
    color: #585758; }
    .header.header-transparent .container .header-link a:hover, .header.header-transparent .container .header-link a.active {
      color: #A5C6CB; }
  .header.header-transparent .dropdown-categories-brands .dropdown-toggle {
    color: #585758; }
    .header.header-transparent .dropdown-categories-brands .dropdown-toggle:hover, .header.header-transparent .dropdown-categories-brands .dropdown-toggle.active {
      color: #A5C6CB; }
  .header {
    padding-bottom: 13px; }
    .header .container .header-bottom {
      min-height: 100px;
      height: auto;
      max-height: none;
      -ms-flex-direction: row;
      flex-direction: row; }
      .header .container .header-bottom .flex-left {
        display: none;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        padding: 0 10px 0 15px;
        -ms-flex-pack: center;
        justify-content: center; }
      .header .container .header-bottom .flex-right {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0 15px 0 15px; }
        .header .container .header-bottom .flex-right .header-mobile-placeholder {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-order: 2;
          order: 2; }
        .header .container .header-bottom .flex-right .header-mobile-logo {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-order: 3;
          order: 3;
          margin: 0;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-pack: center;
          justify-content: center;
          -ms-flex-align: center;
          align-items: center;
          height: 110px; }
          .header .container .header-bottom .flex-right .header-mobile-logo a {
            width: 100%;
            max-width: 230px;
            height: 45px; }
        .header .container .header-bottom .flex-right .header-mobile-search {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-order: 6;
          order: 6;
          margin: 0;
          -ms-flex: 1 1 100%;
          flex: 1 1 100%;
          -ms-flex-pack: center;
          justify-content: center; }
          .header .container .header-bottom .flex-right .header-mobile-search .dropdown {
            width: 100%;
            max-width: 650px; }
            .header .container .header-bottom .flex-right .header-mobile-search .dropdown form input {
              border-radius: 0;
              width: 100%;
              max-width: 650px;
              font-size: 16px; }
            .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu {
              width: 100%;
              padding-left: 7px;
              padding-right: 7px; }
              .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap {
                height: 74px; }
                .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap .sd-flex {
                  padding: 7px;
                  min-width: 0; }
                  .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap .sd-flex p {
                    min-width: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                  .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap .sd-flex a p:last-of-type {
                    margin-top: 0; }
                .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap .sd-flex-1 {
                  -ms-flex: 0 0 74px;
                  flex: 0 0 74px; }
                .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap .sd-flex-2 {
                  -ms-flex: 0 1 100%;
                  flex: 0 1 100%; }
                .header .container .header-bottom .flex-right .header-mobile-search .dropdown .search-dropdown-menu .sd-product-wrap .sd-product-image {
                  width: 60px;
                  height: 60px; }
        .header .container .header-bottom .flex-right .header-mobile-cart {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-order: 5;
          order: 5;
          margin: 0 0 0 20px; }
        .header .container .header-bottom .flex-right .header-mobile-burger {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-order: 1;
          order: 1;
          margin: 0 20px 0 0; }
          .header .container .header-bottom .flex-right .header-mobile-burger .burger-toggle {
            text-align: left; }
        .header .container .header-bottom .flex-right .header-mobile-account {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-order: 4;
          order: 4;
          margin: 0 0 0 20px; }
          .header .container .header-bottom .flex-right .header-mobile-account .dropdown-toggle {
            text-align: right; }
      .header .container .header-bottom .logo {
        width: 269px;
        height: 69px; }
        .header .container .header-bottom .logo a {
          width: 269px;
          height: 69px; }
      .header .container .header-bottom .dropdown-cart {
        width: 40px;
        height: 40px; }
        .header .container .header-bottom .dropdown-cart .dropdown-toggle {
          width: 40px; }
        .header .container .header-bottom .dropdown-cart .dropdown-menu {
          top: 50px; }
        .header .container .header-bottom .dropdown-cart .cart-icon {
          width: 40px;
          height: 40px;
          background-size: 25px 22px; }
          .header .container .header-bottom .dropdown-cart .cart-icon.alternative {
            background-size: 60px; }
  .container-category .category-image .category-title {
    font-size: 22px; }
  .row-popular .popular-header,
  .row-products .products-header,
  .row-newest .newest-header {
    height: 40px;
    font-size: 24px; }
  .row .other-subheader {
    height: 40px;
    font-size: 24px; }
  .row .other-header {
    height: 40px;
    font-size: 24px; }
  .container-category .category-breadcrumb,
  .container-category .vendor-breadcrumb,
  .container-vendor .category-breadcrumb,
  .container-vendor .vendor-breadcrumb {
    font-size: 14px; }
  .header .dropdown-user .dropdown-menu {
    right: 20px; }
  .header-secondary {
    display: none; }
  .header .dropdown-categories-brands .dropdown-menu {
    width: 100%; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap {
    -ms-flex-direction: column;
    flex-direction: column; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
    margin-bottom: 15px; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left {
    border-bottom: 1px solid #898989; }
  .info-block .info-block-price .info-block-flex:last-of-type {
    font-size: 13px; }
  .page-header-flex {
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 20px; }
    .page-header-flex .flex-left {
      -ms-flex-align: start;
      align-items: flex-start;
      margin-bottom: 15px; }
    .page-header-flex .flex-right {
      -ms-flex-align: start;
      align-items: flex-start;
      margin-bottom: 15px; }
    .page-header-flex h2 {
      font-size: 28px; }
      .page-header-flex h2 small {
        display: block;
        margin-top: 7px; }
  .container-cart .page-header {
    min-height: 0;
    background-size: 30px 27px;
    background-position: right 25px top 25px; }
    .container-cart .page-header h2 {
      margin-top: 0; }
    .container-cart .page-header p {
      margin-top: 10px;
      margin-right: 0; }
  .show-mobile-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%; }
  .cart-table-flex {
    font-size: 13px;
    line-height: 13px; }
    .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex,
    .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex,
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex {
      padding: 7px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:first-child,
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:first-child,
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:first-child {
        padding-left: 7px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:last-child,
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:last-child,
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:last-child {
        padding-right: 7px; }
    .cart-table-flex .cart-header-flex {
      display: none; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 95px;
        flex: 0 0 95px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(4) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 110px;
        flex: 0 0 110px; }
      .cart-table-flex .cart-header-flex .cart-row-flex .cart-col-flex:nth-of-type(5) {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px; }
    .cart-table-flex .cart-body-flex .cart-row-flex {
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-direction: column;
      flex-direction: column; }
      .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 100%;
        min-height: 53px; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex > div:nth-of-type(2) {
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: end;
          justify-content: flex-end; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
          -ms-flex-pack: start;
          justify-content: flex-start;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-order: 1;
          order: 1; }
          .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(1) > div:nth-of-type(2) {
            -ms-flex-preferred-size: 140px;
            flex-basis: 140px; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
          -ms-flex-pack: end;
          justify-content: flex-end;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-order: 2;
          order: 2; }
          .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(2) > div:nth-of-type(2) {
            -ms-flex-preferred-size: 140px;
            flex-basis: 140px; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
          -ms-flex-pack: center;
          justify-content: center;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-order: 4;
          order: 4; }
          .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(3) > div:nth-of-type(2) {
            -ms-flex-preferred-size: 140px;
            flex-basis: 140px; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(4) {
          -ms-flex-pack: end;
          justify-content: flex-end;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-order: 3;
          order: 3; }
          .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(4) > div:nth-of-type(2) {
            -ms-flex-preferred-size: 140px;
            flex-basis: 140px; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(5) {
          -ms-flex-pack: end;
          justify-content: flex-end;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          -ms-flex-order: 5;
          order: 5; }
          .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex:nth-of-type(5) > div:nth-of-type(2) {
            -ms-flex-preferred-size: 140px;
            flex-basis: 140px; }
        .cart-table-flex .cart-body-flex .cart-row-flex .cart-col-flex .flex {
          -ms-flex-align: center;
          align-items: center;
          width: 100%; }
    .cart-table-flex .cart-body-flex .cart-row-flex ~ .cart-row-flex {
      border-top: 1px solid #FFFFFF; }
    .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex {
      min-height: 34px; }
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(1) {
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; }
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(2) {
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 110px;
        flex: 0 0 110px; }
      .cart-table-flex .cart-footer-flex .cart-row-flex .cart-col-flex:nth-of-type(3) {
        display: none;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -ms-flex: 0 0 90px;
        flex: 0 0 90px; }
  .cart-product-image {
    height: 39px;
    width: 39px;
    min-width: 39px; }
  .container-help {
    padding-left: 0;
    padding-right: 0; }
    .container-help .flex-wrap {
      -ms-flex-direction: column;
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px; }
      .container-help .flex-wrap .flex:first-of-type {
        -ms-flex-pack: center;
        justify-content: center; }
      .container-help .flex-wrap .flex:last-of-type {
        -ms-flex-pack: center;
        justify-content: center; }
    .container-help .contact-text {
      padding: 7px; }
    .container-help .contact-form form .form-control {
      font-size: 16px; }
  .header .container .header-top {
    margin-left: -15px;
    margin-right: -15px; }
  .header .container .header-bottom {
    margin-left: -15px;
    margin-right: -15px; }
  .header.header-transparent {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    background: #FFFFFF;
    border-bottom: 1px solid #EEEEEE; }
    .header.header-transparent .header-wrap {
      position: relative; }
    .header.header-transparent .dropdown-pages .dropdown-toggle,
    .header.header-transparent .dropdown-languages .dropdown-toggle,
    .header.header-transparent .dropdown-currency .dropdown-toggle,
    .header.header-transparent .dropdown-user .dropdown-toggle {
      color: #585758; } }
  @media (max-width: 767px) and (max-width: 768px) {
    .header.header-transparent .dropdown-user.alternative {
      display: none !important; } }

@media (max-width: 767px) {
    .header.header-transparent .dropdown-categories-brands .dropdown-toggle {
      color: #585758; }
    .header.header-transparent .container .header-link a {
      color: #585758; }
    .header.header-transparent .container .header-top .header-info {
      color: #585758; }
      .header.header-transparent .container .header-top .header-info span,
      .header.header-transparent .container .header-top .header-info i,
      .header.header-transparent .container .header-top .header-info a,
      .header.header-transparent .container .header-top .header-info a:hover {
        color: #585758; }
  #shop-carousel .carousel-inner .item {
    background-size: cover;
    padding-bottom: 66.66%; }
    #shop-carousel .carousel-inner .item img {
      display: none; }
    #shop-carousel .carousel-inner .item video {
      display: none; }
    #shop-carousel .carousel-inner .item .slide-link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .container-carousel .slide-widget {
    top: 50%; }
  .container-carousel .carousel .carousel-indicators {
    bottom: 55px; } }

@media (max-width: 575px) {
  .container-home .row-categories .category-wrap .category-title h3,
  .container-home .row-promos .category-wrap .category-title h3,
  .container-promos .row-categories .category-wrap .category-title h3,
  .container-promos .row-promos .category-wrap .category-title h3 {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-categories-wrap,
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-brands-wrap {
    display: block;
    -ms-flex-direction: column;
    flex-direction: column; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left,
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right {
    max-width: none; }
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-title,
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title {
      font-size: 18px; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories a {
    min-height: 0;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1; }
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-left .list-categories a span {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      font-size: 16px;
      margin: 7px 0; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-title {
    padding-left: 0; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands a {
    min-height: 0;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1; }
    .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands a span {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      font-size: 16px;
      margin: 7px 0; }
  .header .dropdown-categories-brands .dropdown-menu .list-wrap .list-right .list-brands a:last-child {
    padding-bottom: 0; }
  .header .container .header-bottom .flex-right .header-mobile-placeholder {
    -ms-flex-order: 3;
    order: 3;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .header .container .header-bottom .flex-right .header-mobile-logo {
    -ms-flex-order: 1;
    order: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    height: 70px;
    margin-top: 13px;
    margin-bottom: 13px; }
  .header .container .header-bottom .flex-right .header-mobile-search {
    -ms-flex-order: 6;
    order: 6;
    margin-top: 13px; }
  .header .container .header-bottom .flex-right .header-mobile-cart {
    -ms-flex-order: 5;
    order: 5;
    margin-left: 30px; }
  .header .container .header-bottom .flex-right .header-mobile-burger {
    -ms-flex-order: 2;
    order: 2;
    margin-right: 30px; }
  .header .container .header-bottom .flex-right .header-mobile-account {
    -ms-flex-order: 4;
    order: 4;
    margin-left: 30px; }
  .container-carousel .carousel .carousel-control {
    width: 30px;
    max-width: 30px;
    min-width: 30px; }
  .container-carousel .carousel-arrow-down {
    width: 50px;
    height: 50px;
    background-size: 30px 30px; }
  .carousel .slide-widget .slide-widget-text {
    margin-left: 30px;
    margin-right: 30px;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    font-size: 24px; } }

@media (max-width: 480px) {
  .btn-lg {
    height: 55px;
    padding: 15px;
    font-size: 16px;
    line-height: 25px;
    min-width: 135px; }
  .header .container .header-bottom {
    height: auto;
    float: none; }
    .header .container .header-bottom .logo {
      width: 100%;
      height: 69px; }
      .header .container .header-bottom .logo a {
        width: 269px;
        height: 69px;
        margin-left: auto;
        margin-right: auto; }
  .header .container .header-bottom {
    min-height: 70px; }
    .header .container .header-bottom .flex-left {
      -ms-flex-pack: center;
      justify-content: center; }
      .header .container .header-bottom .flex-left .logo {
        width: 100%;
        height: 65px; }
        .header .container .header-bottom .flex-left .logo a {
          width: 100%;
          height: 65px;
          background-position: left center; }
    .header .container .header-bottom .flex-right {
      -ms-flex-pack: center;
      justify-content: center; }
  .container-category .category-image .category-title {
    font-size: 18px; }
  .row-popular .popular-header,
  .row-products .products-header,
  .row-newest .newest-header {
    height: 30px;
    font-size: 18px; }
  .row .other-subheader {
    height: 30px;
    font-size: 18px; }
  .row .other-header {
    height: 30px;
    font-size: 18px; }
  .container-cart .cart-proceed-row .form-group {
    text-align: center; }
  .container-cart .cart-proceed-row .input-group {
    text-align: center; }
    .container-cart .cart-proceed-row .input-group a.btn {
      margin: 10px; } }

.productPrice small {
  font-size: 12px; }

.go-back-login span {
  color: #ffffff; }

.alternative-col {
  position: relative;
  min-height: 1px;
  padding: unset;
  text-align: left;
  float: left;
  width: 25%; }
  @media (max-width: 990px) {
    .alternative-col {
      text-align: center;
      float: none;
      width: unset; } }

.alternative-container {
  width: 90%;
  margin: auto; }

.alternative-linebreak {
  margin-top: 5%;
  height: 2px;
  background: #008ac6;
  margin-right: -15px;
  margin-left: -15px; }

.alternative-footer-icon {
  float: right;
  margin-top: 5%;
  margin-bottom: 10%; }

.page-header.alternative {
  background-image: url("/themes/standard/images/milestone-basket.png");
  background-size: 150px; }

.privatsikring-theme {
  background-image: url("/themes/standard/images/ostifterne.png");
  position: relative;
  width: 70%;
  height: 98px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.product-discount-ribbon-money {
  position: absolute;
  top: 20px;
  left: -60px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 200px;
  height: 40px;
  background-color: orange;
  border-color: orange;
  border-radius: 0;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  letter-spacing: 1px;
  transform: rotate(-45deg); }
  .product-discount-ribbon-money::after {
    content: '\00a0kr.'; }

.card-body {
  padding: 60px 60px 60px 60px; }

.card-header {
  height: 70px; }

.btn-card-accordion {
  color: black;
  width: 100%;
  height: 70px;
  text-align: left;
  padding-left: 30px;
  overflow: hidden; }

.btn-card-accordion:focus {
  border-color: white; }

.footer-nav p {
  font-family: 'Raleway', sans-serif; }

@media (min-width: 1200px) {
  .orange-product-box {
    height: 363px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .orange-product-box {
    height: 548px; } }

@media (min-width: 768px) and (max-width: 992px) {
  .orange-product-box {
    height: 438px; } }

@media (max-width: 767px) {
  .orange-text-box {
    display: none; }
  .home-orange-text-box {
    display: block; } }

@media (min-width: 768px) {
  .home-orange-text-box {
    display: none; } }

/*# sourceMappingURL=custom.css.map */
